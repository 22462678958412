import clsx from 'clsx';
import React from 'react';
import { useState } from 'react';
import "./DayProgram.css"
import EventDetails from './EventDetails';
import uuid from 'react-uuid'


export default function DayProgram({ darker, onChange, events }) {

  const [selectedEvent, setSelectedEvent] = useState(null)
  const minY = 183;
  const cellHeight = 30;
  const maxY = 180 + 12 * cellHeight;

  const handleClick = (info) => {
    const yPos = info.clientY;
    const timePos = 24 * (yPos - minY) / (maxY - minY)
    if (timePos > 0) {
      const hours = Math.floor(timePos)
      const minutes = Math.floor((timePos - hours) * 60)
      const duration = 15
      const height = duration > 60 ? (duration * 30 / 120) : 15;
      const newEvents = [...events]
      newEvents.push({ hours, minutes, duration, id: uuid(), yPos: (yPos - minY), height })
      onChange(newEvents);
    }
  }

  const handleEventClick = (event) => {
    setSelectedEvent(event)
  }
  const handleEventChanged = (event) => {
    const newEvents = [...events];
    const targetEvent = newEvents.filter((e) => e.id === event.id)[0]
    targetEvent.hours = event.hours;
    targetEvent.minutes = event.minutes;
    targetEvent.duration = event.duration;
    targetEvent.height = event.duration > 60 ? (event.duration * 30 / 120) : 15;
    const timePos = event.hours + (event.minutes / 60)
    targetEvent.yPos = (timePos * (maxY - minY) / 24)

    onChange(newEvents);
    setSelectedEvent(null);
  }

  const handleEventDeleted = (event) => {
    const newEvents = [...events];
    const targetEvent = newEvents.filter((e) => e.id === event.id)[0]
    const index = newEvents.indexOf(targetEvent);
    newEvents.splice(index, 1);
    onChange(newEvents);

  }

  const eventTimeString = (event) => {
    const { hours, minutes } = event
    const h = hours > 9 ? `${hours}` : `0${hours}`
    const m = minutes > 9 ? `${minutes}` : `0${minutes}`
    return `${h}:${m}`
  }

  return <div>

    {selectedEvent && <EventDetails event={selectedEvent} onChange={handleEventChanged} />}

    <div className='day-program__wrapper'>
      <div className='day-program__events' >
        {events.map((e) =>
          <div className='day-program__events__item' key={e.id} style={{ height: e.height, top: e.yPos }}>
            <div className='day-program__events__item__content'>
              <button className='day-program__events__item__delete-button' onClick={() => handleEventDeleted(e)}>
                <ion-icon name="trash"></ion-icon>
              </button>
              <div className='day-program__events__item__content__labels' onClick={() => handleEventClick(e)}>
                <div className='day-program__events__item__time-label'>{eventTimeString(e)}</div>
                <div className='day-program__events__item__duration-label'>{`${e.duration} mins`}</div>
              </div>
            </div>

          </div>)}

      </div>
      <div className={clsx('day-program',
        { "day-program--darker": darker })}
        onClick={handleClick}>

      </div>
    </div>
  </div>


}