import React, { Component } from "react";
import "../css/device.css";
import "../css/focus.css";
import ConveyorBand from "./Devices/ConveyorBand/ConveyorBand";
import Conveyor from "./Devices/ConveyorBand/Conveyor";

class Device extends Component {

  constructor(props) {
    super();
    const { device, groupId } = props;
    var deviceTypes = [
      "switch",
      "light",
      "contactSensor",
      "motionSensor",
      "temperatureSensor",
      "ambientSensor",
      "thermostat",
      "doorbell",
      "lock",
      "fan",
      "lightRgb",
      "analogSensor",
      "door",
      "sprinkler",
      "oven",
      "boiler",
      "boilerBsb",
      "conveyorBand",
      "conveyor"
    ]
    if (device == null) {
      this.state = {
        extended: false,
        editing: false,
        deleting: false,
        visible: true,
        name: "----",
        type: "switch",
        deviceTypes: deviceTypes
      };
    } else {
      this.state = {
        extended: false,
        editing: false,
        settings: false,
        deleting: false,
        visible: true,
        name: device.name,
        type: device.type,
        lastMessageToServer: new Date(),
        groupId: groupId,
        deviceTypes: deviceTypes,
        users: device.users,
        durationBlocked: 1,
        logs: false,

        percentage: device.percentage,
        setpoint: device.setpoint,

        settingsData: device.settings,
        settingsChanged: false,

        focusInfo: "nivel1"
      };
    }


  }

  //Even listener Callbacks
  viewClicked = () => {
    console.log("View Clicked", this);
    const { extended } = this.state;
    if (extended) this.setState({ extended: false });
    else this.setState({ extended: true });
  };
  actionClicked = () => {
    const { onEvent, device } = this.props;
    const { extended } = this.state;
    console.log("Action Clicked", this);
    if ((device.type === "lock" || device.type === "door") && !extended) {
      var action = device.lockedState ? false : true;
      onEvent({
        name: "controlDevice",
        device: { deviceId: device._id },
        directive: { lockedState: action }
      });
    } else {
      var onOff = "";
      if (device.powerState === "ON") {
        onOff = "OFF";
      } else {
        onOff = "ON";
      }
      onEvent({
        name: "controlDevice",
        device: { deviceId: device._id },
        directive: { powerState: onOff }
      });
    }


  };
  innerActionClicked = () => {
    const { onEvent, device } = this.props;
    console.log("Inner Action Clicked", this);
    if (device.type === "lock" || device.type === "door") {
      var action = device.lockedState ? false : true;
      onEvent({
        name: "controlDevice",
        device: { deviceId: device._id },
        directive: { lockedState: action }
      });
    }


  };
  editClicked = () => {
    console.log("Edit Clicked", this);
    const { editing } = this.state;
    if (editing) this.setState({ editing: false });
    else this.setState({ editing: true });
  };
  deleteClicked = () => {
    console.log("Delete Clicked", this);
    const { deleting } = this.state;
    if (deleting) this.setState({ deleting: false });
    else this.setState({ deleting: true });
  };
  deleteConfirmClicked = () => {
    const { onEvent, device } = this.props;
    console.log("Delete Confirm Clicked", this);
    this.setState({ visible: false, extended: false });
    setTimeout(function () {
      onEvent({
        name: "deleteDevice",
        device: { deviceId: device._id, groupId: null }
      });
    }, 1000)

  };
  deleteFromClicked = () => {
    const { onEvent, device } = this.props;
    const groupIdInitial = this.props.groupId;
    const { name, type } = this.state;

    console.log("Remove from group Clicked", this);
    if (name !== null && type !== null) {
      onEvent({
        name: "editDevice",
        device: {
          name: name,
          type: type,
          deviceId: device._id,
          groupId: "nogroup",
          initialGroupId: groupIdInitial
        }
      });
    }
    this.setState({ deleting: false, editing: false });
  };
  cancelClicked = () => {
    console.log("Cancel Clicked", this);
    this.setState({ deleting: false, editing: false });
  };
  editDoneClicked = () => {
    const { onEvent, device } = this.props;
    const groupIdInitial = this.props.groupId;
    const { name, type, groupId } = this.state;

    console.log("Edit Done Clicked", this);
    if (name !== null && type !== null) {
      onEvent({
        name: "editDevice",
        device: {
          name: name,
          type: type,
          deviceId: device._id,
          groupId: groupId,
          initialGroupId: groupIdInitial
        }
      });
    }
    this.setState({ deleting: false, editing: false });
  };
  addDeviceClicked = () => {
    const { extended } = this.state
    if (!extended)
      this.setState({ extended: true })
  };
  addCancelClicked = () => {
    console.log("Add Cancel Clicked", this);
    this.setState({ extended: false, type: "switch" });
  };
  addDoneClicked = () => {
    const { onEvent, groupId } = this.props;
    const { name, type, deviceId } = this.state;
    console.log("Add done", this);
    if (name !== null && type !== null) {
      onEvent({
        name: "addDevice",
        device: {
          name: name,
          type: type,
          deviceId: deviceId,
          groupId: groupId
        }
      });
      this.setState({ extended: false, type: "switch" });
    }
  };
  checkLogsClicked = () => {
    const { logs } = this.state;
    this.setState({ logs: !logs });
  };

  nameChanged = value => {
    console.log("nameChanged");

    this.setState({ name: value });
  };
  typeChanged = value => {
    console.log("typeChanged");
    this.setState({ type: value });
  };
  groupChanged = value => {
    console.log("groupChanged", value);
    this.setState({ groupId: value });
  };
  usersChanged = value => {
    console.log("users Changed", value);
    var arr = [];
    var tokens = value.split(",");
    for (let i = 0; i < tokens.length; i++) {
      const element = tokens[i];
      arr.push(element);
    }
    this.setState({ users: arr });
  };
  setpointChange = (value, n) => {
    console.log("setpoint Changed", value);
    this.setState({ setpoint: value })
  };
  setpoint2Change = (value,) => {
    console.log("setpoint2 Changed", value);
    this.setState({ setpoint2: value })
  };
  setpointChangeEnd = value => {
    const { setpoint } = this.state;
    const { onEvent, device } = this.props;
    onEvent({
      name: "controlDevice",
      device: { deviceId: device._id },
      directive: { setpoint: setpoint }
    });
    console.log("Sending", value);
    this.setState({ percentage: value })
  };
  setpoint2ChangeEnd = value => {
    const { setpoint2 } = this.state;
    const { onEvent, device } = this.props;
    onEvent({
      name: "controlDevice",
      device: { deviceId: device._id },
      directive: { setpoint2: setpoint2 }
    });
    console.log("Sending", value);
    this.setState({ percentage: value })
  };
  percentageChange = value => {
    this.setState({ percentage: value })
  };
  percentageChangeEnd = value => {
    const { percentage } = this.state;
    const { onEvent, device } = this.props;
    onEvent({
      name: "controlDevice",
      device: { deviceId: device._id },
      directive: { percentage: percentage }
    });
    console.log("Sending", value);
    this.setState({ percentage: value })
  };
  colorChanged = value => {

    const { onEvent, device } = this.props;
    onEvent({
      name: "controlDevice",
      device: { deviceId: device._id },
      directive: { color: value.getAttribute("fill") }
    });
    console.log("Sending", value.getAttribute("fill"));
  };
  resetFlames = value => {

    const { onEvent, device } = this.props;
    onEvent({
      name: "controlDevice",
      device: { deviceId: device._id },
      directive: { flames: 0 }
    });
  };
  resetOpenCount = value => {

    const { onEvent, device } = this.props;
    onEvent({
      name: "controlDevice",
      device: { deviceId: device._id },
      directive: { openCount: 0 }
    });
  };

  settingsChanged = value => {
    this.setState({ settingsData: value, settingsChanged: true });
  }

  //Api calls
  updatePercentage() {
    const { lastMessageToServer, percentage } = this.state;
    const { onEvent, device } = this.props;
    if (Date.now() - lastMessageToServer > 500) {
      onEvent({
        name: "controlDevice",
        device: { deviceId: device._id },
        directive: { percentage: percentage }
      });
      this.setState({ lastMessageToServer: new Date() });
    }
  };

  updateSettings() {
    const { onEvent, device } = this.props;
    const { settingsData, settingsChanged } = this.state;
    if (settingsChanged)
      onEvent({
        name: "controlDevice",
        device: { deviceId: device._id },
        directive: { settings: settingsData }
      });
    this.setState({ settings: false, settingsChanged: false });
  }

  //Helper functions
  getLocalDate(date, timezone) {
    if (date && timezone) {
      var lastConnectionLocal = new Date(date)
      var offset = parseInt(timezone.split("GMT")[1])
      //Calculate Summer time offset
      if (new Date() > new Date("2019", "02", "32", "00", "00", "00") && new Date() < new Date("2019", "09", "28", "23", "59", "59")) {
        offset = offset - 2;
      } else {
        offset = offset - 1;
      }
      lastConnectionLocal.setHours(lastConnectionLocal.getHours() + offset)
      return lastConnectionLocal;
    } else return null;
  }
  getShortDate(date) {
    if (date == null) return "----/--/--"
    date = new Date(date)
    let year = date.getFullYear() - 2000;
    return (year + "/" + (date.getMonth() + 1)) + "/" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
  }
  translate(expresion, lang) {
    if (lang !== "EN" && lang !== "ES" && lang !== "DE" && lang !== "CZ" && lang !== "FR" && lang !== "PT" && lang !== "IT" && lang !== "JP") {
      lang = "EN";
    }
    var libr = {
      adddevicein: { EN: "Add device in", ES: "Agregar disp. en", DE: "Gerät hinzufügen in", CZ: "Přidat zařízení ve", FR: "Ajouter un appareil dans", PT: "Adicionar dispositivo", IT: "Aggiungi dispositivo in", JP: "にデバイスを追加", },
      analogSensor: { EN: "Analog sensor", ES: "Análogo" },
      ambientSensor: { EN: "Analog sensor", ES: "Sensor de ambiente" },
      blockedmessage: { EN: "This device has been temporarily blocked because it was sending MQTT updates too soon, please avoid sending MQTT updates in for loops without delays. Blocked until:", ES: "Este dispositivo se ha bloqueado temporalmente porque estaba enviando actualizaciones MQTT demasiado rápido, evita enviar actualizaciones MQTT en bucles sin delays. Bloqueado hasta:", DE: "Dieses Gerät wurde vorübergehend blockiert, da zu früh MQTT-Updates gesendet wurden. Vermeiden Sie es, MQTT-Updates für Schleifen ohne Verzögerungen zu senden. Gesperrt bis:", CZ: "Toto zařízení bylo dočasně zablokováno, protože odeslalo aktualizace MQTT příliš brzy. Vyhněte se odesílání aktualizací MQTT do smyček bez zpoždění. Blokováno do:", FR: "Cet appareil a été temporairement bloqué parce qu'il envoyait des mises à jour MQTT trop tôt, s'il vous plaît éviter d'envoyer des mises à jour MQTT dans des boucles sans retard. Bloqué jusqu'à ce que:", PT: "Este dispositivo foi temporariamente bloqueada porque foi o envio de atualizações MQTT muito cedo, por favor, evite o envio de atualizações MQTT em loops sem atrasos. Bloqueado até que:", IT: "Questo dispositivo è stato bloccato temporaneamente perché era l'invio di aggiornamenti MQTT troppo presto, si prega di evitare l'invio di aggiornamenti MQTT in cicli for, senza ritardi. Bloccato fino al:", JP: "それは遅延なしのループのためにMQTTの更新を送信することを回避してください、あまりにも早くMQTTアップデートを送信したため、このデバイスは、一時的にブロックされています。までブロックされました：", },
      cancel: { EN: "Cancel", ES: "Cancelar", DE: "Stornieren", CZ: "zrušení", FR: "Annuler", PT: "Cancelar", IT: "Annulla", JP: "キャンセル", },
      checklogs: { EN: "Check logs", ES: "Ver logs" },
      connectionrejected: { EN: "Connection rejected", ES: "conexión rechazada", DE: "Verbindung abgelehnt", CZ: "spojení odmítnuto", FR: "connexion rejetée", PT: "Conexão rejeitado", IT: "Connessione rifiutata", JP: "接続が拒否されました", },
      contactSensor: { EN: "Contact sensor", ES: "sensor de contacto", DE: "Kontaktsensor", CZ: "Kontaktní snímače", FR: "Capteur de contact", PT: "sensor de contato", IT: "sensore di contatto", JP: "接触センサ", },
      closed: { EN: "Closed", ES: "Cerrado" },
      deviceid: { EN: "Device ID", ES: "ID del dispositivo", DE: "Geräte ID", CZ: "ID zařízení", FR: "Reference de l'appareil", PT: "ID de dispositivo", IT: "ID del dispositivo", JP: "デバイスID", },
      detected: { EN: "Detected", ES: "Detectado" },
      doorbell: { EN: "Doorbell", ES: "Timbre", DE: "Türklingel", CZ: "Domovní zvonek", FR: "sonnette", PT: "Campainha", IT: "Campanello di casa", JP: "ドアベル", },
      door: { EN: "door", ES: "Puerta" },
      fan: { EN: "Fan", ES: "Ventilador" },
      group: { EN: "Group", ES: "Grupo", DE: "Gruppe", CZ: "Skupina", FR: "Groupe", PT: "Grupo", IT: "Gruppo", JP: "グループ", },
      hidelogs: { EN: "Hide logs", ES: "Cerrar logs" },
      info: { EN: "Info", ES: "Info", DE: "Info", CZ: "Info", FR: "Info", PT: "informações", IT: "Informazioni", JP: "インフォ", },
      lastsensed: { EN: "Last sensed", ES: "Ultima detección", DE: "zuletzt erfasste", CZ: "Poslední vycítil", FR: "dernière détectée", PT: "Última sentiu", IT: "Ultima percepì", JP: "前回検出されました", },
      lastevent: { EN: "Last event", ES: "Último evento", DE: "Letzte Veranstaltung", CZ: "Poslední akce", FR: "Dernier événement", PT: "Último evento", IT: "Ultimo evento", JP: "最後のイベント", },
      light: { EN: "Light", ES: "Luz", DE: "Licht", CZ: "Světlo", FR: "Lumière", PT: "Luz", IT: "Leggero", JP: "光", },
      lightRgb: { EN: "Light RGB", ES: "Luz RGB" },
      limitdevicesonline: { EN: "You have reached the limit of connected devices for your plan, would you like to upgrade to Pro?", ES: "Has alcanzado el límite de dispositivos conectados para tu plan, ¿le gustaría actualizar al plan Pro?", DE: "Sie haben das Limit der Online-Geräte für Ihren Plan erreicht. Möchten Sie ein Upgrade auf Pro durchführen?", CZ: "Dosáhli jste limitu připojených zařízení pro svůj plán, chtěli byste upgradovat na Pro?", FR: "Vous avez atteint la limite des appareils raccordés à votre plan, voulez-vous passer à Pro?", PT: "Você atingiu o limite de dispositivos conectados para o seu plano, que gostaria de atualizar para o Pro?", IT: "Hai raggiunto il limite di dispositivi collegati per il piano, ti piacerebbe per l'aggiornamento a Pro?", JP: "あなたは、あなたの計画のために接続されたデバイスの限界に達している、あなたはプロにアップグレードしたいと思いますか？", },
      lock: { EN: "Lock", ES: "Cerradura", DE: "Schloss", CZ: "Zámek", FR: "Fermer à clé", PT: "bloqueio", IT: "Serratura", JP: "ロック", },
      lockaction: { EN: "Lock", ES: "Bloquear", DE: "Sperren", CZ: "Zamiknout", FR: "proche", PT: "Travar", IT: "Serratura", JP: "ロック", },
      locked: { EN: "Locked", ES: "Bloqueado" },
      unlock: { EN: "Unlock", ES: "Desbloquear", DE: "Öfnen", CZ: "Odemknout", FR: "Ouvrir", PT: "Destravar", IT: "Sbloccare", JP: "ロック解除", },
      unlocked: { EN: "Unlocked", ES: "Desbloqueado" },
      name: { EN: "Name", ES: "Nombre", DE: "Name", CZ: "název", FR: "Nom", PT: "Nome", IT: "Nome", JP: "名前", },
      neveronline: { EN: "Never online", ES: "Nunca en línea", DE: "nie Online", CZ: "nikdy on-line", FR: "jamais en ligne", PT: "Nunca on-line", IT: "mai in linea", JP: "決してオンライン", },
      newdevice: { EN: "New device", ES: "Nuevo dispositivo", DE: "Neues Gerät", CZ: "nové zařízení", FR: "Nouvel appareil", PT: "Novo dispositivo", IT: "Nuovo dispositivo", JP: "新デバイス", },
      nogroup: { EN: "No group", ES: "ningún grupo", DE: "keine Gruppe", CZ: "žádná skupina", FR: "Aucun groupe", PT: "nenhum grupo", IT: "Nessun gruppo", JP: "ませんグループ", },
      notdetected: { EN: "Not detected", ES: "No detectado" },
      offlinesince: { EN: "Offline since", ES: "Desc. desde", DE: "Offline seit", CZ: "Offline od", FR: "Hors ligne depuis", PT: "off-line desde", IT: "offline dal", JP: "オフライン以来", },
      on: { EN: "On", ES: "Encendido" },
      off: { EN: "Off", ES: "Apagado" },
      online: { EN: "Online", ES: "En línea", DE: "Online", CZ: "Online", FR: "en ligne", PT: "Conectados", IT: "in linea", JP: "オンライン", },
      open: { EN: "Open", ES: "Abierto" },
      openaction: { EN: "Open", ES: "Abrir" },
      oven: { EN: "Oven", ES: "Horno" },
      percentage: { EN: "Percentage", ES: "Porcentaje", DE: "Prozentsatz", CZ: "Procento", FR: "Pourcentage", PT: "Percentagem", IT: "Percentuale", JP: "パーセンテージ", },
      motionSensor: { EN: "Motion sensor", ES: "Sensor de movimiento", DE: "Bewegungssensor", CZ: "Pohybový senzor", FR: "Capteur de mouvement", PT: "Sensor de movimento", IT: "Sensore di movimento", JP: "モーションセンサー", },
      setpoint: { EN: "Set point", ES: "Punto deseado", DE: "Sollwert", CZ: "Žádaná hodnota", FR: "Point de consigne", PT: "Set point", IT: "set point", JP: "セットポイント", },
      status: { EN: "Status", ES: "Estado", DE: "Status", CZ: "Stav", FR: "Statut", PT: "estado", IT: "Stato", JP: "状態", },
      switch: { EN: "Switch", ES: "Interruptor", DE: "Schalter", CZ: "Přepínač", FR: "Commutateur", PT: "Interruptor", IT: "Interruttore", JP: "スイッチ", },
      sprinkler: { EN: "Sprinkler", ES: "Riego" },
      temperature: { EN: "Temperature", ES: "Temperatura", DE: "Temperatur", CZ: "Teplota", FR: "Température", PT: "Temperatura", IT: "Temperatura", JP: "温度", },
      temperatureSensor: { EN: "Temperature Sensor", ES: "Sensor de temperatura", DE: "Temperatursensor", CZ: "Senzor teploty", FR: "Capteur de température", PT: "Sensor de temperatura", IT: "Termometro", JP: "温度センサー", },
      thermostat: { EN: "Thermostat", ES: "Termostato", DE: "Thermostat", CZ: "Termostat", FR: "Thermostat", PT: "termóstato", IT: "Termostato", JP: "サーモスタット", },
      turnoff: { EN: "Turn off", ES: "Apagar", DE: "Schalte aus", CZ: "Vypnout", FR: "Éteindre", PT: "Desligar", IT: "Spegni", JP: "消す", },
      turnon: { EN: "Turn on", ES: "Encender", DE: "Anschalten", CZ: "Zapnout", FR: "Allumer", PT: "Ligar", IT: "Accendere", JP: "オンにする", },
      type: { EN: "Type", ES: "Tipo", DE: "Art", CZ: "Typ", FR: "Type", PT: "Tipo", IT: "genere", JP: "タイプ", },
      units: { EN: "Units", ES: "Unidades", DE: "Einheiten", CZ: "Jednotky", FR: "Unités", PT: "Unidades", IT: "unità", JP: "単位", },
      value: { EN: "Value", ES: "Valor", DE: "Wert", CZ: "Hodnota", FR: "Valeur", PT: "Valor", IT: "Valore", JP: "値", },
      view: { EN: "View", ES: "Ver", DE: "Anzeigen", CZ: "Ukázat", FR: "Vue", PT: "Visão", IT: "Visualizza", JP: "見る", },
      deviceinfo: { EN: "Device Info", ES: "Información del dispositivo", DE: "Geräteinformationen", CZ: "Informace o zařízení", FR: "Info appareil", PT: "Informação do dispositivo", IT: "Informazioni sul dispositivo", JP: "機器情報", },
      created: { EN: "Created", ES: "Creado", DE: "Erstellt", CZ: "Vytvořeno", FR: "Créé", PT: "Criada", IT: "Creato", JP: "作成した", },
      blocked: { EN: "Blocked", ES: "Bloqueado", DE: "verstopft", CZ: "Blokováno", FR: "Bloqué", PT: "Bloqueado", IT: "bloccato", JP: "ブロックされました", },
      lastconnection: { EN: "Last connection", ES: "Última conexión", DE: "Letzter", CZ: "Poslední připojení", FR: "dernière connexion", PT: "Última conexão", IT: "Ultima connessione", JP: "最後の接続", },
      until: { EN: "Until", ES: "Hasta", DE: "Bis um", CZ: "Dokud", FR: "Jusqu'à", PT: "Até", IT: "Fino a", JP: "まで", },
      yes: { EN: "Yes", ES: "SI", DE: "Ja", CZ: "Ano", FR: "Oui", PT: "sim", IT: "sì", JP: "はい", },
      no: { EN: "No", ES: "NO", DE: "Nein", CZ: "Ne", FR: "Non", PT: "Não", IT: "No", JP: "番号", },
      block: { EN: "Block", ES: "Bloquear", DE: "Block", CZ: "Blok", FR: "Bloquer", PT: "Quadra", IT: "Bloccare", JP: "ブロック", },
      manufacturer: { EN: "Manufacturer", ES: "Fabricante", DE: "Hersteller", CZ: "Výrobce", FR: "Fabricant", PT: "Fabricante", IT: "fabbricante", JP: "メーカー", },
      update: { EN: "Update", ES: "Actualizar", DE: "Update", CZ: "Aktualizovat", FR: " à jour", PT: "atualizar", IT: "gli", JP: "更新ユーザー", },
      unblock: { EN: "Unblock", ES: "Desbloquear", DE: "Entblocken", CZ: "Odblokovat", FR: "Débloquer", PT: "Desbloquear", IT: "Sbloccare", JP: "ブロックを解除", },
      close: { EN: "Close", ES: "Cerrar", DE: "Schließen", CZ: "Zavřít", FR: "Fermer", PT: "Fechar", IT: "Vicino", JP: "閉じる", },
      delete: { EN: "Delete", ES: "Eliminar", DE: "Löschen", CZ: "Delete", FR: "Supprimer", PT: "Excluir", IT: "Elimina", JP: "[削除]", },
      users: { EN: "Users", ES: "Usuarios", DE: "Benutzer", CZ: "uživatelé", FR: "Utilisateurs", PT: "Usuários", IT: "utenti", JP: "ユーザー", },
      boiler: { ES: "Caldera OTH" },
      boilerBsb: { ES: "Caldera BSB" },
      conveyorBand: { ES: "Sorter" },
      conveyor: { ES: "Alimentador" }
    };
    if (libr[expresion] == null) return "ERROR";
    if (libr[expresion][lang] == null) return "ERROR";
    return libr[expresion][lang];
  }

  //Render Functions
  renderOnlineStatusLabl() {
    const { lang, device, timezone } = this.props;

    if (device.connectionRejected) {
      return <h6 className="status-title problem-status ">{this.translate("connectionrejected", lang)}</h6>
    } else if (device.connected) {
      return (
        <div>
          <div className="online-mark">

            <ion-icon src="img/online.svg"></ion-icon>
          </div>
          <h6 className="status-title online-status">{this.translate("online", lang)}</h6>

        </div>
      )
    } else if (!device.connected) {
      if (timezone != null) {
        if (!device.lastConnection) {
          return <h6 className="status-title offline-status">{this.translate("neveronline", lang)}</h6>
        }
        else {

          return <h6 className="status-title offline-status">{this.translate("offlinesince", lang) + ": " + this.getShortDate(device.lastConnection)}</h6>

        }
      } else {
        return <h6 className="status-title offline-status">{this.translate("offlinesince", lang)}</h6>
      }
    }
  }
  renderOnlineDot() {
    const { device } = this.props
    if (device.connected) {
      return (
        <div className="online-dot" onClick={event => { this.colorChanged(event.target) }}>
          <svg className="rgb-color-crcl" width="8" height="8"> <circle cx="4" cy="4" r="4" fill="rgb(23,161,165)" /> </svg>
        </div>
      )
    } else {
      return (
        <div className="online-dot" onClick={event => { this.colorChanged(event.target) }}>
          <svg className="rgb-color-crcl" width="8" height="8"> <circle cx="4" cy="4" r="4" fill="rgb(200,200,200)" /> </svg>
        </div>
      )
    }
  }
  renderPowerStateFeature() {
    const { lang, device } = this.props;
    if (["conveyorBand", "conveyor"].includes(device.type)) { return; }
    let src = "img/device-features/switch-on.png";
    let state = this.translate("on", lang);
    if (device.powerState === "OFF") {
      src = "img/device-features/switch-off.png"
      state = this.translate("off", lang);
    }
    return (
      <div className="row device-feature">
        <div className="col-4">
          <img src={src} alt="toogle on" />
        </div>
        <div className="col-6">
          <h5 className="feature-value-lbl">{state}</h5>
        </div>
      </div>
    )

  }
  renderExtraFeature() {
    const { lang, device } = this.props;
    const { percentage, setpoint, settings, settingsData } = this.state;
    let src = "";
    let state = "";
    let state2 = "";

    if (device.type === "analogSensor") {
      src = "img/device-features/analog.png"
      state = device.value;
      return (
        <div className="row device-feature">
          <div className="col-4">
            <img src={src} alt="toogle on" />
          </div>
          <div className="col-6">
            <h5 className="feature-value-lbl">{state}</h5>
          </div>
        </div>
      )
    } else if (device.type === "temperatureSensor") {
      src = "img/device-features/temperatureSensor.png"
      state = device.temperature + "° " + device.scale;
      return (
        <div className="row device-feature">
          <div className="col-4">
            <img src={src} alt="toogle on" />
          </div>
          <div className="col-6">
            <h5 className="feature-value-lbl">{state}</h5>
          </div>
        </div>
      )


    } else if (device.type === "ambientSensor") {
      src = "/img/device-features/temperatureSensor.png"
      let src2 = "/img/device-features/humidity.png";
      let src3 = "/img/device-features/pressure.png";
      state = device.temperature + "° " + device.scale;
      let state2 = device.humidity != null ? device.humidity + " %" : "-- %";
      let state3 = device.pressure != null ? device.pressure : "---";
      return (
        <div className="row device-feature">
          <div className="col">
            <div className="row">
              <div className="col">
                <img src={src} alt="toogle on" width="30" />
              </div>
            </div>
            <div className="row">
              <h5 className="feature-value-lbl">{state}</h5>
            </div>
          </div>

          <div className="col">
            <div className="row">
              <div className="col">
                <img src={src2} alt="toogle on" width="30" />
              </div>
            </div>
            <div className="row">
              <h5 className="feature-value-lbl">{state2}</h5>
            </div>
          </div>

          <div className="col">
            <div className="row">
              <div className="col">
                <img src={src3} alt="toogle on" width="30" />
              </div>
            </div>
            <div className="row">
              <h5 className="feature-value-lbl">{state3}</h5>
            </div>
          </div>

        </div>
      )


    } else if (device.type === "thermostat") {
      src = "img/device-features/thermostat.png"
      state = device.temperature + "° " + device.scale;
      let min = device.scale === "C" ? "10" : "50";
      let max = device.scale === "C" ? "40" : "104";
      return (
        <div>
          <h5 className="device-info-label">
            {this.translate("setpoint", lang) + ": " + setpoint + "° " + device.scale}
          </h5>
          <div className="slidecontainer">
            <input
              type="range"
              min={min}
              max={max}
              value={setpoint}
              className="slider"
              onChange={event => this.setpointChange(event.target.value)}
              onMouseUp={event => this.setpointChangeEnd(event.target.value)}
              onTouchEnd={event => this.setpointChangeEnd(event.target.value)}
            />
          </div>
          <div className="row device-feature">
            <div className="col-4">
              <img src={src} alt="toogle on" />
            </div>
            <div className="col-6">
              <h5 className="feature-value-lbl">{state}</h5>
              <h5 className="feature-value-lbl">{state2}</h5>
            </div>
          </div>
        </div>

      )


    } else if (device.type === "boiler") {
      src = "img/device-features/thermostat.png"
      state = device.temperature + "° " + device.scale;
      let state2 = device.temperature2 + "° " + device.scale;
      let min = device.scale === "C" ? "35" : "50";
      let max = device.scale === "C" ? "60" : "104";
      return (
        <div>
          <h5 className="device-info-label">
            {this.translate("setpoint", lang) + ": " + setpoint + "° " + device.scale}
          </h5>
          <div className="slidecontainer">
            <input
              type="range"
              min={min}
              max={max}
              value={setpoint}
              className="slider"
              onChange={event => this.setpointChange(event.target.value)}
              onMouseUp={event => this.setpointChangeEnd(event.target.value)}
              onTouchEnd={event => this.setpointChangeEnd(event.target.value)}
            />
          </div>
          <div className="boiler-info">
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="boiler-info-row">
                    <div className="mini-icon"><img src="/img/device-features/temperatureSensor.svg" alt="temp" width="15" /></div>
                    <div>CH: {state}</div>
                  </div>
                </div>
                <div className="col">
                  <div className="boiler-info-row">
                    <div className="mini-icon"><img src="/img/device-features/temperatureSensor.svg" alt="temp" width="15" /></div>
                    <div>DH: {state2}</div>
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="col">
                  <div className="boiler-info-row">
                    <div className="mini-icon"><img src="/img/device-features/modulation.svg" alt="temp" width="15" /></div>
                    <div>Mod: {device.modulation}</div>
                  </div>
                </div>
                <div className="col">
                  <div className="boiler-info-row">
                    <div className="mini-icon"><img src={"/img/device-features/" + (device.fault === 0 ? "ok.svg" : "alert.svg")} alt="temp" width="15" /></div>
                    <div>Err: {device.fault}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="boiler-info-row">
                    <div className="mini-icon"><img src="/img/device-features/pressure.svg" alt="temp" width="15" /></div>
                    <div>Pres.: {device.pressure} bar</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      )


    } else if (device.type === "boilerBsb") {
      src = "img/device-features/temperatureSensor.png"
      state = device.bsb ? device.bsb.v8310 + "° " + device.scale : "";

      return (
        <div>
          <button onClick={() => this.setState({ focusDevice: device })}>Detalles</button>
          <div className="row device-feature">
            <div className="col-4">
              <img src={src} alt="toogle on" />
            </div>
            <div className="col-6">
              <h5 className="feature-value-lbl">{state}</h5>
              <h5 className="feature-value-lbl">{state2}</h5>
            </div>
          </div>
        </div>

      )


    } else if (device.type === "conveyorBand") {
      return <ConveyorBand device={device} />
    } else if (device.type === "conveyor") {
      return <Conveyor device={device} />
    } else if (device.type === "contactSensor") {
      src = "img/device-features/contactSensor.png"
      state = device.detectionState ? this.translate("closed", lang) : this.translate("open", lang);
      if (device.lastDetection)
        state2 = this.translate("lastsensed", lang) + " " + (1 + device.lastDetection.getMonth()) + "/" + device.lastDetection.getDate() + " " + device.lastDetection.getHours() + ":" + device.lastDetection.getMinutes();

      return (
        <div className="row device-feature">
          <div className="col-4">
            <img src={src} alt="toogle on" />
          </div>
          <div className="col-6">
            <h5 className="feature-value-lbl">{state}</h5>
            <h5 className="feature-value-lbl">{state2}</h5>
          </div>
        </div>
      )


    } else if (device.type === "oven") {
      if (settings) {
        return (
          <div className="settings-box">
            <div className="settings-form">
              <div className="settings-row d-flex">
                <div className="settings-icon"><ion-icon name="person"></ion-icon> </div>
                <input className="settings-input" type="text" placeholder={device.settings.contact}
                  onChange={(evt) => { settingsData.contact = evt.target.value; this.settingsChanged(settingsData); }} />
              </div>
              <div className="settings-row d-flex">
                <div className="settings-icon"><ion-icon name="md-at"></ion-icon> </div>
                <input className="settings-input" type="text" placeholder={device.settings.email}
                  onChange={(evt) => { settingsData.email = evt.target.value; this.settingsChanged(settingsData); }} />
              </div>
              <div className="settings-row d-flex">
                <div className="settings-icon"><ion-icon name="call"></ion-icon> </div>
                <input className="settings-input" type="text" placeholder={device.settings.phone}
                  onChange={(evt) => { settingsData.phone = evt.target.value; this.settingsChanged(settingsData); }} />
              </div>
              <div className="settings-row d-flex">
                <div className="settings-icon"><ion-icon name="pin"></ion-icon> </div>
                <input className="settings-input" type="text" placeholder={device.settings.location}
                  onChange={(evt) => { settingsData.location = evt.target.value; this.settingsChanged(settingsData); }} />
              </div>
              <div className="settings-row d-flex">
                <div className="settings-icon"><ion-icon name="document"></ion-icon> </div>
                <textarea rows="4" cols="50" className="settings-input-big" placeholder={device.settings.notes}
                  onChange={(evt) => { settingsData.notes = evt.target.value; this.settingsChanged(settingsData); }} />
              </div>
            </div>
            <div className="save-settings-btn" onClick={this.updateSettings.bind(this)}>
              Guardar
            </div>
          </div >
        )
      } else
        return (
          <div className="row device-feature">
            <div className="oven-box">
              <div className="oven-handle"></div>
              <div className={device.alarm ? "oven-window oven-window-alarm" : "oven-window oven-window-ok"}>
                {device.alarm ? "Alarma" : "OK"}
              </div>
              <div className="oven-control-box d-flex">
                <div className="oven-control">
                  <div className="oven-flame-count">{device.flames}</div>
                  <ion-icon src="img/device-features/flame.svg"></ion-icon>
                </div>
                <div className="oven-control">
                  <div className="oven-flame-count">{device.openCount}</div>
                  <ion-icon src={device.lockedState ? "img/device-features/door-open.svg" : "img/device-features/door-closed.svg"}></ion-icon>
                </div>
                <div className="oven-control">
                  <div className="oven-flame-count">{device.temperature + "°C"}</div>
                  <ion-icon src="img/device-features/temperatureSensor.svg"></ion-icon>
                </div>
              </div>

              <div className="oven-rst-btn oven-rst-flames-btn" onClick={this.resetFlames.bind(this)}><ion-icon name="refresh"></ion-icon></div>
              <div className="oven-rst-btn oven-rst-open-count-btn" onClick={this.resetOpenCount.bind(this)}><ion-icon name="refresh"></ion-icon></div>
              <div className="oven-config-btn" onClick={() => { this.setState({ settings: true }) }}><ion-icon name="cog"></ion-icon></div>
              {device.lockedState ? <div className="oven-door-open-msg">Puerta abierta</div> : null}
            </div>
          </div >
        )


    } else if (device.type === "motionSensor") {
      src = "img/device-features/motionSensor.png"
      state = device.detectionState ? this.translate("detected", lang) : this.translate("notdetected", lang);
      if (device.lastDetection)
        state2 = this.translate("lastsensed", lang) + " " + (1 + device.lastDetection.getMonth()) + "/" + device.lastDetection.getDate() + " " + device.lastDetection.getHours() + ":" + device.lastDetection.getMinutes();
      return (
        <div className="row device-feature">
          <div className="col-4">
            <img src={src} alt="toogle on" />
          </div>
          <div className="col-6">
            <h5 className="feature-value-lbl">{state}</h5>
            <h5 className="feature-value-lbl">{state2}</h5>
          </div>
        </div>
      )


    } else if (device.type === "doorbell") {
      src = "img/device-features/doorbell.png"
      state = device.detectionState ? this.translate("detected", lang) : this.translate("notdetected", lang);
      if (device.lastDetection)
        state2 = this.translate("lastsensed", lang) + " " + (1 + device.lastDetection.getMonth()) + "/" + device.lastDetection.getDate() + " " + device.lastDetection.getHours() + ":" + device.lastDetection.getMinutes();
      return (
        <div className="row device-feature">
          <div className="col-4">
            <img src={src} alt="toogle on" />
          </div>
          <div className="col-6">
            <h5 className="feature-value-lbl">{state}</h5>
            <h5 className="feature-value-lbl">{state2}</h5>
          </div>
        </div>
      )


    } else if (device.type === "light" || device.type === "fan") {
      let step = device.type === "fan" ? 20 : 1;
      let min = device.type === "fan" ? 20 : 1;
      return (
        <div>
          <h5 className="device-info-label">
            {this.translate("percentage", lang) + ": " + percentage + "%"}
          </h5>
          <div className="slidecontainer">
            <input
              type="range"
              min={min}
              max="100"
              step={step}
              value={percentage}
              className="slider"
              onChange={event => this.percentageChange(event.target.value)}
              onMouseUp={event => this.percentageChangeEnd(event.target.value)}
              onTouchEnd={event => this.percentageChangeEnd(event.target.value)}
            />
          </div>
        </div>
      )


    } else if (device.type === "lightRgb") {


      return (
        <div className="row rgb-pallete">
          <div className="col-3 rgb-color" onClick={event => { this.colorChanged(event.target) }}>
            <svg className="rgb-color-crcl" width="30" height="30"> <circle cx="15" cy="15" r="15" fill="rgb(255,255,255)" /> </svg>
          </div>
          <div className="col-3 rgb-color" onClick={event => { this.colorChanged(event.target) }}>
            <svg className="rgb-color-crcl" width="30" height="30"> <circle cx="15" cy="15" r="15" fill="rgb(255,0,0)" /> </svg>
          </div>
          <div className="col-3 rgb-color" onClick={event => { this.colorChanged(event.target) }}>
            <svg className="rgb-color-crcl" width="30" height="30"> <circle cx="15" cy="15" r="15" fill="rgb(0,0,255)" /> </svg>
          </div>
          <div className="col-3 rgb-color" onClick={event => { this.colorChanged(event.target) }}>
            <svg className="rgb-color-crcl" width="30" height="30"> <circle cx="15" cy="15" r="15" fill="rgb(255,255,0)" /> </svg>
          </div>
          <div className="col-3 rgb-color" onClick={event => { this.colorChanged(event.target) }}>
            <svg className="rgb-color-crcl" width="30" height="30"> <circle cx="15" cy="15" r="15" fill="rgb(128,0,128)" /> </svg>
          </div>
          <div className="col-3 rgb-color" onClick={event => { this.colorChanged(event.target) }}>
            <svg className="rgb-color-crcl" width="30" height="30"> <circle cx="15" cy="15" r="15" fill="rgb(0,255,0)" /> </svg>
          </div>
          <div className="col-3 rgb-color" onClick={event => { this.colorChanged(event.target) }}>
            <svg className="rgb-color-crcl" width="30" height="30"> <circle cx="15" cy="15" r="15" fill="rgb(255,192,203)" /> </svg>
          </div>
          <div className="col-3 rgb-color" onClick={event => { this.colorChanged(event.target) }}>
            <svg className="rgb-color-crcl" width="30" height="30"> <circle cx="15" cy="15" r="15" fill="rgb(255,165,0)" /> </svg>
          </div>

        </div>

      )


    } else if (device.type === "door") {
      src = "img/device-features/door-closed.png"
      state = this.translate("closed", lang);
      let action = this.translate("openaction", lang);

      if (device.lockedState === false) {
        src = "img/device-features/door-open.png";
        state = this.translate("open", lang);
        action = this.translate("close", lang);
      }

      return (
        <div className="row device-feature">
          <div className="col-4">
            <img src={src} alt="door state" />
          </div>
          <div className="col-6">
            <h5 className="feature-value-lbl">{state}</h5>
            <button className="view-btn" onClick={this.innerActionClicked.bind(this)}>{action}</button>
          </div>

        </div>
      )


    } else if (device.type === "lock") {
      src = "img/device-features/lock.png"
      state = this.translate("locked", lang);
      let action = this.translate("unblock", lang);

      if (device.lockedState === false) {
        src = "img/device-features/unlock.png";
        state = this.translate("unlocked", lang);
        action = this.translate("lockaction", lang);
      }

      return (
        <div className="row device-feature">
          <div className="col-4">
            <img src={src} alt="door state" />
          </div>
          <div className="col-6">
            <h5 className="feature-value-lbl">{state}</h5>
            <button className="view-btn" onClick={this.innerActionClicked.bind(this)}>{action}</button>
          </div>

        </div>
      )


    } else return null;

  }
  renderDeviceInfo() {
    const { extended, editing, deviceTypes, logs } = this.state;
    const { lang, device, admin } = this.props;
    if (extended && !editing) {
      if (logs) {
        return (
          <div className="device-info-wrapper">
            <div className="logs-wrapper">
              {device.log.map((log, idx) => {
                if (idx < 7) return null;
                return (
                  <div key={idx}>
                    <h4 className="log-date">{this.getShortDate(log.date) + " - " + log.by}</h4>
                    <h4 className="log">{log.action}</h4>
                  </div>)

              })}
            </div>
            <h5 className="check-logs-lbl" onClick={this.checkLogsClicked.bind(this)}>{this.translate("hidelogs", lang)}</h5>
          </div>
        );

      }
      else {
        return (
          <div className="device-info-wrapper col">
            <div className="row device-info-line">
              <h5 className="device-info-label">ID:</h5>
              <h5 className="device-id">{device._id}</h5>
            </div>
            <div className="row device-info-line">
              <h5 className="device-info-label">{this.translate("type", lang) + ": "}</h5>
              <h5 className="device-info-value">{this.translate(device.type, lang)}</h5>
            </div>
            {this.renderExtraFeature()}
            {this.renderPowerStateFeature()}
            <h5 className="check-logs-lbl" onClick={this.checkLogsClicked.bind(this)}>{this.translate("checklogs", lang)}</h5>
          </div>
        );

      }
    } else if (extended && editing) {
      return (
        <div className="device-info-wrapper">
          <h5 className="info-title">{this.translate("name", lang)}</h5>
          <input
            type="text"
            className="form-control"
            defaultValue={device.name}
            onChange={event => this.nameChanged(event.target.value)}
          />
          {admin &&
            <div>
              <h5 className="info-title">{this.translate("type", lang)}</h5>
              <select
                name="dev-type"
                id="dev-type-select"
                className="device-type-select"
                defaultValue={deviceTypes[0]}
                onChange={event => this.typeChanged(event.target.value)}
              >
                {deviceTypes.map(tp => (
                  <option key={tp} value={tp}>
                    {this.translate(tp, lang)}
                  </option>
                ))}
              </select>
            </div>}
          <h5 className="info-title">{this.translate("group", lang)}</h5>
          {this.renderGroupList()}
        </div>
      );
    } else return null;
  }
  renderGroupList() {
    const { lang, groupId, groups } = this.props;
    var groupList = []
    if (groupId === "nogroup") groupList.push({ _id: "nogroup", name: this.translate("nogroup", lang) })
    groups.forEach(gr => {
      if (gr._id === groupId) {
        groupList.push(gr);
      }
    });
    groups.forEach(gr => {
      if (gr._id !== groupId) {
        groupList.push(gr);
      }
    });

    return <select
      name="dev-type"
      id="dev-type-select"
      className="device-type-select"
      defaultValue={groupId}
      onChange={event => this.groupChanged(event.target.value)}
    >
      {groupList.map(gr => (
        <option key={gr._id} value={gr._id}>
          {gr.name}
        </option>
      ))}
    </select>
  }
  renderAddDevice() {
    const { extended, deviceTypes } = this.state;
    const { lang, groupName } = this.props;
    if (extended) {
      return (
        <div>
          <h6 className="new-device-title">
            {this.translate("newdevice", lang)}
          </h6>
          <div className="device-info-wrapper">
            <h5 className="info-title">{this.translate("name", lang)}</h5>
            <input
              maxLength="25"
              type="text"
              className="form-control"
              readOnly={false}
              onChange={event => this.nameChanged(event.target.value)}
            />
            <h5 className="info-title">{this.translate("type", lang)}</h5>
            <select
              name="dev-type"
              id="dev-type-select"
              className="device-type-select"
              defaultValue={deviceTypes[0]}
              onChange={event => this.typeChanged(event.target.value)}
            >
              {deviceTypes.map(tp => (
                <option key={tp} value={tp}>
                  {this.translate(tp, lang)}
                </option>
              ))}
            </select>
          </div>
          <div className="btn-group device-btns d-flex">
            <button
              onClick={this.addDoneClicked}
              className="view-btn"
            >
              <ion-icon name="checkmark"></ion-icon>
              {" OK"}
            </button>
            <button
              onClick={this.addCancelClicked}
              className="action-btn"
            >
              <ion-icon name="close"></ion-icon>
              {" " + this.translate("cancel", lang)}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h6 className="device-name">
            {this.translate("adddevicein", lang) + " " + groupName}
          </h6>
          <div className="big-icon">
            <ion-icon name="add"></ion-icon>
          </div>
        </div>
      );
    }
  }
  renderDeviceButtons() {
    const { extended, editing, deleting } = this.state;
    const { lang, admin } = this.props;
    if (extended && !editing && !deleting) {
      return (
        <div className="btn-group device-btns d-flex">
          <button
            onClick={this.viewClicked}
            className="action-btn"
          >
            <ion-icon name="arrow-dropup"></ion-icon>
          </button>
          <button
            onClick={this.editClicked}
            className="action-btn"
          >
            <ion-icon name="create"></ion-icon>
          </button>
          {admin && <button
            onClick={this.deleteClicked}
            className="action-btn"
          >
            <ion-icon name="trash"></ion-icon>
          </button>}
          {admin && <button
            onClick={this.actionClicked}
            className="action-btn"
          >
            {this.renderActionButton(false)}
          </button>}
        </div>
      );
    } else if (extended && editing && !deleting) {
      return (
        <div className="btn-group device-btns d-flex">
          <button
            onClick={this.editDoneClicked}
            className="view-btn"
          >
            <ion-icon name="checkmark"></ion-icon>
            {" OK"}
          </button>
          <button
            onClick={this.addCancelClicked}
            className="action-btn"
          >
            <ion-icon name="close"></ion-icon>
            {" " + this.translate("cancel", lang)}
          </button>
        </div>
      );
    } else if (extended && !editing && deleting) {
      return (
        <div className="btn-group device-btns d-flex">
          <button
            onClick={this.deleteConfirmClicked}
            className="action-btn"
          >
            <ion-icon name="trash"></ion-icon>
          </button>
          <button
            onClick={this.deleteFromClicked}
            className="action-btn"
          >
            <ion-icon name="apps"></ion-icon>
            <ion-icon name="arrow-forward"></ion-icon>
          </button>
          <button
            onClick={this.cancelClicked}
            className="action-btn"
          >
            <ion-icon name="close"></ion-icon>
            {" " + this.translate("cancel", lang)}
          </button>
        </div>
      );
    } else {

      return (
        <div className="btn-group device-btns d-flex">
          <button
            onClick={this.viewClicked}
            className="view-btn"
          >
            <ion-icon name="eye"></ion-icon>
            {" " + this.translate("view", lang)}
          </button>
          <button
            onClick={this.actionClicked}
            className="action-btn"
          >
            {this.renderActionButton()}
          </button>
        </div>
      );
    }
  }
  renderActionButton(showLabel = true) {
    const { lang, device } = this.props;
    const { extended } = this.state;
    var icon;
    var label;
    if (!extended && (device.type === "lock")) {
      if (device.lockedState === true) {
        label = this.translate("unlock", lang);
        icon = "unlock"
      } else {
        label = this.translate("lockaction", lang);
        icon = "lock"
      }
    } else if (!extended && (device.type === "door")) {
      if (device.lockedState === true) {
        label = this.translate("openaction", lang);
        icon = "unlock"
      } else {
        label = this.translate("close", lang);
        icon = "lock"
      }
    } else {
      icon = "wifi";
      if (device.powerState === "ON") {
        label = this.translate("turnoff", lang);
      } else {
        label = this.translate("turnon", lang);
      }
    }

    if (showLabel) {
      return <div>
        <ion-icon name={icon}></ion-icon>
        {" " + label}
      </div>
    } else {
      return <div>
        <ion-icon name={icon}></ion-icon>
      </div>
    }

  }
  renderDeviceIcon() {
    const { device, view } = this.props;
    var classnames = "icon "
    if (view === "card") classnames = "device-icon icon ";
    if (device.type === "light") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon name="bulb"></ion-icon>
        </div>
      );
    } else if (device.type === "switch") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/switch.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "thermostat") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/thermostat.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "temperatureSensor") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon name="thermometer"></ion-icon>
        </div>
      );
    } else if (device.type === "ambientSensor") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon name="thermometer"></ion-icon>
        </div>
      );
    } else if (device.type === "contactSensor") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon name="pause"></ion-icon>
        </div>
      );
    } else if (device.type === "motionSensor") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/motionSensor.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "doorbell") {
      classnames +=
        device.detectionState === true ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/doorbell.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "lock") {
      classnames +=
        device.lockedState === true ? "device-icon-on " : "device-icon-off ";
      var iconName = device.lockedState ? "lock" : "unlock"

      return (
        <div className={classnames}>
          <ion-icon name={iconName}></ion-icon>
        </div>
      );
    } else if (device.type === "analogSensor") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/analog.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "lightRgb") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/bulbRgb.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "door") {
      let src = device.lockedState === true ? "door-closed.svg" : "door-open.svg";
      classnames +=
        device.lockedState === false ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src={"img/device-features/" + src}></ion-icon>
        </div>
      );
    } else if (device.type === "sprinkler") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/sprinkler.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "fan") {
      classnames +=
        device.powerState === "ON" ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/fan.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "oven") {
      classnames +=
        device.alarm ? "device-icon-alert " : "device-icon-on ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/oven.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "boiler" || device.type === "boilerBsb") {
      classnames +=
        device.powerState ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/boiler.svg"></ion-icon>
        </div>
      );
    } else if (device.type === "conveyorBand" || device.type === "conveyor") {
      classnames +=
        device.powerState ? "device-icon-on " : "device-icon-off ";
      return (
        <div className={classnames}>
          <ion-icon src="img/device-features/conveyor.svg"></ion-icon>
        </div>
      );

    } else {
      return (
        <div className={classnames}>
          <ion-icon name="bulb"></ion-icon>
        </div>
      );
    }
  }
  renderBlockUntilInfo(time, duration) {
    var d = new Date(time)
    d.setMinutes(d.getMinutes() + duration)
    var month = d.getMonth() + 1;
    return d.getDate() + "/" + month + "/" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes()
  }

  renderFocusInfo() {
    const { device } = this.props;
    const { focusInfo } = this.state
    if (!device.bsb) return null;
    return <div className="focus-bg">
      <div className="focus-box">
        <div onClick={() => this.setState({ focusDevice: null })} className="focus-close-btn">Cerrar</div>
        <div className="d-flex justify-content-around">
          <div className={focusInfo === "nivel1" ? "focus-info-type-btn selected" : "focus-info-type-btn"} onClick={() => { this.setState({ focusInfo: "nivel1" }) }}>Diag. Fuentes de calor</div>
          <div className={focusInfo === "nivel2" ? "focus-info-type-btn selected" : "focus-info-type-btn"} onClick={() => { this.setState({ focusInfo: "nivel2" }) }}>Diag. cascada</div>
          <div className={focusInfo === "nivel3" ? "focus-info-type-btn selected" : "focus-info-type-btn"} onClick={() => { this.setState({ focusInfo: "nivel3" }) }}>Test de entradas y salidas</div>
          <div className={focusInfo === "nivel4" ? "focus-info-type-btn selected" : "focus-info-type-btn"} onClick={() => { this.setState({ focusInfo: "nivel4" }) }}>Fallas historicas</div>
        </div>
        <div>
          {focusInfo === "nivel1" ? <div>
            {this.renderBoilerBsbInfoRow("70", "version", "version", device.bsb.v70)}
            {this.renderBoilerBsbInfoRow("8304", "Diagnóstico del generador", "temperatura de la caldera", "Encendido")}
            {this.renderBoilerBsbInfoRow("8310", "Diagnóstico del generador", "temperatura de la caldera", device.bsb.v8310 + " °C")}
            {this.renderBoilerBsbInfoRow("8314", "Diagnóstico del generador", "temperatura real de retorno de la caldera", device.bsb.v8314 + " °C")}
            {this.renderBoilerBsbInfoRow("8316", "Diagnóstico del generador", "valor real de la temperatura de los gases de combustión", device.bsb.v8316 + " °C")}

            {this.renderBoilerBsbInfoRow("8323", "Diagnóstico del generador", "vel ventilador", device.bsb.v8323 + " rpm")}
            {this.renderBoilerBsbInfoRow("8324", "Diagnóstico del generador", "set ponit de ventilador rpm limite maximo", device.bsb.v8324 + " rpm")}
            {this.renderBoilerBsbInfoRow("8325", "Diagnóstico del generador", "porcentaje de rpm ventilador", device.bsb.v8325 + " %")}
            {this.renderBoilerBsbInfoRow("8326", "Diagnóstico del generador", "modulacion de quemador", device.bsb.v8326 + " %")}
            {this.renderBoilerBsbInfoRow("8327", "Diagnóstico del generador", "presionde agua", device.bsb.v8327 + " bar")}

            {this.renderBoilerBsbInfoRow("8378", "Diagnóstico del generador", "calefacción total de energía de gas", device.bsb.v8378 + " kWh")}
            {this.renderBoilerBsbInfoRow("8379", "Productor de diagnóstico", "energía total de gas agua potable", device.bsb.v8379 + " kWh")}
            {this.renderBoilerBsbInfoRow("8380", "Diagnóstico del generador", "energia total del gas", device.bsb.v8380 + " kWh")}
            {this.renderBoilerBsbInfoRow("8381", "Generador de diagnóstico", "reinicio de calentamiento de energía de gas", device.bsb.v8381 + " kWVh")}
            {this.renderBoilerBsbInfoRow("8382", "Diagnóstico del generador", "reinicio de TWWW de energía de gas", device.bsb.v8382 + " kvh")}
            {this.renderBoilerBsbInfoRow("8383", "Diagnóstico del generador", "energia del gas", device.bsb.v8383 + " kWh")}

          </div> : null}

          {focusInfo === "nivel2" ? <div>
            {this.renderBoilerBsbInfoRow("8138", "Diagnóstico del generador", "temperatura impulso o flujo en  cascada", device.bsb.v8383 + " °C")}

          </div> : null}

          {focusInfo === "nivel3" ? <div>
            {this.renderBoilerBsbInfoRow("7830", "Diagnóstico del generador", "temperatura sonda dx22", device.bsb.v7830 + " °C")}
            {this.renderBoilerBsbInfoRow("7841", "Diagnóstico del generador", "estado contacto h1", "Encendido")}
          </div> : null}

          {focusInfo === "nivel4" ? <div>
            {this.renderBoilerBsbInfoRow("6800", "Fallas históricas", "Falla 1 Fecha", device.bsb.v6800)}
            {this.renderBoilerBsbInfoRow("6801", "Fallas históricas", "Falla 1 Código", device.bsb.v6801)}
            {this.renderBoilerBsbInfoRow("6802", "Fallas históricas", "Falla 2 Fecha", device.bsb.v6802)}
            {this.renderBoilerBsbInfoRow("6803", "Fallas históricas", "Falla 2 Código", device.bsb.v6803)}
            {this.renderBoilerBsbInfoRow("6804", "Fallas históricas", "Falla 3 Fecha", device.bsb.v6804)}
            {this.renderBoilerBsbInfoRow("6805", "Fallas históricas", "Falla 3 Código", device.bsb.v6805)}
            {this.renderBoilerBsbInfoRow("6806", "Fallas históricas", "Falla 4 Fecha", device.bsb.v6806)}
            {this.renderBoilerBsbInfoRow("6807", "Fallas históricas", "Falla 4 Código", device.bsb.v6807)}
            {this.renderBoilerBsbInfoRow("6808", "Fallas históricas", "Falla 5 Fecha", device.bsb.v6808)}
            {this.renderBoilerBsbInfoRow("6809", "Fallas históricas", "Falla 5 Código", device.bsb.v6809)}
            {this.renderBoilerBsbInfoRow("6810", "Fallas históricas", "Falla 6 Fecha", device.bsb.v6810)}
            {this.renderBoilerBsbInfoRow("6811", "Fallas históricas", "Falla 6 Código", device.bsb.v6811)}
            {this.renderBoilerBsbInfoRow("6812", "Fallas históricas", "Falla 7 Fecha", device.bsb.v6812)}
            {this.renderBoilerBsbInfoRow("6813", "Fallas históricas", "Falla 7 Código", device.bsb.v6813)}

          </div> : null}

          <div>



          </div>

        </div>
      </div>
    </div>
  }

  renderBoilerBsbInfoRow(code, section, description, state) {
    return <div className="focus-info-row">
      <div className="padding-5">{code}</div>
      <div className="focus-info-description">
        <div className="focus-description-title">{section}</div>
        <div className="focus-description-subtitle"> {description}</div>
      </div>
      <div className="padding-5">{state}</div>
    </div>
  }


  render() {
    const { extended, visible, users, durationBlocked, focusDevice } = this.state;
    const { device, admin, name, onEvent, lang, timezone, view } = this.props;
    if (focusDevice) {
      return this.renderFocusInfo()
    } else if (device != null && view === "card") {
      let classesMain = visible ? "animated flipInX" : "animated flipOutX";
      classesMain += device.connected ? " device-online" : " device-offline";
      classesMain += extended ? " device-extended" : " device";

      if (device.blocked && device.blocked.blocked) {
        classesMain += " device-blocked";
      } else if (device.connectionRejected) {
        classesMain += " device-blocked";
      }

      return (
        <div className={classesMain} onClick={this.deviceClicked}>
          {this.renderDeviceIcon()}
          <h5 className="device-name">{name}</h5>
          {this.renderOnlineStatusLabl()}
          {this.renderDeviceInfo()}
          {this.renderDeviceButtons()}
        </div>
      );

    } else if (device === null && view === "card") {
      let classesMain = "animated fadeIn add-device";
      classesMain += extended ? " device-extended add-device-extended" : " device";
      return (

        admin ? <div
          className={classesMain}
          align="center"
          onClick={this.addDeviceClicked
          }
        >
          {this.renderAddDevice()}
        </div > : null

      );

    } else if (!extended && view === "list") {
      let mainUser = device.users[0]
      if (mainUser == null) { mainUser = "No users" }
      return <div className="admin-card" onClick={this.viewClicked}>
        <div className="row">
          <div className="col-xsm-12 col-md-6">
            {this.renderOnlineDot()}
            {this.renderDeviceIcon()}
            <h6 className="table-field main-label">{name}</h6>
            <h6 className="table-field">{device.connected ? "Online" : "Offline"}</h6>
            <h6 className="table-field">{device.powerState}</h6>
          </div>
          <div className="col-xsm-12 col-md-6">
            <h6 className="table-field">Usuario:</h6>
            <h6 className="table-field main-label">{mainUser}</h6>

          </div>
        </div>
      </div>
    } else if (extended && view === "list") {
      let mainUser = device.users[0]
      if (mainUser == null) { mainUser = "No users" }
      var created = new Date(1970, 1, 1);
      if (device.insertionTimestamp != null) created = new Date(device.insertionTimestamp * 1000);
      return <div className="admin-card">
        <div className="row">
          <div className="col-xsm-12 col-md-6">
            {this.renderOnlineDot()}
            {this.renderDeviceIcon()}
            <h6 className="table-field main-label">{name}</h6>
            <h6 className="table-field">{device.connected ? "Online" : "Offline"}</h6>
            <h6 className="table-field">{device.powerState}</h6>
          </div>
          <div className="col-xsm-12 col-md-6">
            <h6 className="table-field">Usuario:</h6>
            <h6 className="table-field main-label">{mainUser}</h6>

          </div>
        </div>

        <hr></hr>
        <div className="row">

          <div className="col-md-6">
            <h3>{this.translate("deviceinfo", lang)}</h3>
            <hr></hr>
            <div className="row dev-inf-section">
              <div className="col-3">{this.translate("created", lang)}:</div>
              <div className="col-3">
                <h6 className="lbl-mini">{created.getDate() + "/" + created.getMonth() + "/" + created.getFullYear()}</h6>
              </div>
              <div className="col-3">{this.translate("lastconnection", lang)}:</div>
              <div className="col-3">
                <h6 className="lbl-mini">{this.getShortDate(this.getLocalDate(device.lastConnection, timezone))}</h6>
              </div>
            </div>
            {/* <div className="row dev-inf-section">
              <div className="col-3">{this.translate("blocked", lang)}:</div>
              <div className="col-1">
                <h6 className="lbl-mini">{(device.blocked != null && device.blocked.blocked === true) ? "YES" : "NO"}</h6>
              </div>
              <div className="col-2">{this.translate("until", lang)}:</div>
              <div className="col-3">
                <h6 className="lbl-mini">{(device.blocked && device.blocked.duration == null) ? "--/--/-- --:--" : this.renderBlockUntilInfo(this.getLocalDate(device.blocked.time, timezone), device.blocked.duration)}</h6>
              </div>
            </div> */}
            <div className="row dev-inf-section">
              <div className="col-3">{this.translate("users", lang)}:</div>
              <div className="col-9">
                <input className="users-input" type="text" value={users.toString()} onChange={event => this.usersChanged(event.target.value)}></input>
              </div>

            </div>
            <div className="row dev-inf-section">
              <div className="col-2">ID:</div>
              <div className="col-4">
                <h6 >{device._id}</h6>
              </div>

            </div>
            <div className="row dev-inf-section">
              <div className="col-4"> {this.translate("manufacturer", lang)}:</div>
              <div className="col-4"> {device.manufacturer}</div>
            </div>
            <hr></hr>
            <div className="btn-group btn-group-2" role="group">
              <button className="mybtn btn  " onClick={() => onEvent({ name: "blockDevice", device: { deviceId: device._id }, duration: durationBlocked })}>{this.translate("block", lang)}</button>
              <button className="mybtn btn  " onClick={() => onEvent({ name: "updateDeviceUsers", device: { deviceId: device._id, users: users } })}>{this.translate("update", lang)}</button>
              <button className="mybtn" type="button" onClick={() => onEvent({ name: "deleteDevice", device: { deviceId: device._id, groupId: null } })}>{this.translate("delete", lang)}</button>
              <button className="mybtn" type="button" onClick={this.viewClicked}>{this.translate("close", lang)}</button>
            </div>
          </div>
          <div className="col-md-6">
            <h6>Logs</h6>
            <div className="log-area">
              {device.log.map((log, idx) => (
                <div key={"log" + log.date + "-" + idx} className="row">
                  <div className="col-4">
                    <h6 className="date-log-lbl">{this.getShortDate(this.getLocalDate(log.date, timezone))}</h6>
                  </div>
                  <div className="col-5">
                    <h6 className="lbl-mini">{log.action}</h6>
                  </div>
                  <div className="col-3">
                    <h6 className="lbl-mini">{log.by}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    }
    return null
  }
}

export default Device;
