import React, { Component } from "react";
import Group from "../components/Group";
import "../css/devices.css";
import "../css/navbar.css";
import "../css/footer.css";

var api = require("../../utils/api");
var local = require("../../utils/local");
var refreshInterval;

class Devices extends Component {
  constructor(props) {
    super();
    this.state = {
      timezone: props.timezone,
      lang: props.lang,
      loaded: false,
      groups: [],
      devices: [],
      addGroupName: ""
    };
  }

  mynestEvent = event => {
    const { sessionId, onAdmin, lang, setCookie, admin, onStreetLight, onLcdBoardsMode, onSpatialMode, onReportsMode } = this.props;

    if (admin === "Admin") console.log("mynest Event", event);
    if (event.name === "getInfo") {
      if (sessionId !== "") {
        api.getSessionInfo(sessionId).then(
          function (data) {
            if (admin === "Admin")
              console.log(data.data);

            if (data.data.error === "Logged out") {
              clearInterval(refreshInterval);
              setCookie("");
              return;
            }

            this.setState({
              devices: data.data.devices,
              groups: data.data.groups,
              timezone: data.data.userInfo.timezone,
              plan: data.data.userInfo.plan,
              loaded: true
            });
            if (data.data.userInfo.role === "Admin" || data.data.userInfo.role === "Integrator") {
              onAdmin(data.data.userInfo.role);
            }
            if (data.data.userInfo.streetLightView) {
              onStreetLight()
            }
            if (data.data.userInfo.lcdBoardView) {
              onLcdBoardsMode()
            }
            if (data.data.userInfo.spatialView) {
              onSpatialMode()
            }
            if (data.data.userInfo.reportsView) {
              onReportsMode()
            }
          }.bind(this)
        );
      }
      else {
        setCookie("");
      }
    } else if (event.name === "signout") {
      console.log("signing out");

      api.signout(sessionId).then(
        function (data) {
          console.log(data.data);
          if (data.data.result === "Logged out" || data.data.error != null) {
            window.FB.getLoginStatus(function (response) {
              if (response.status === "connected" && response.authResponse != null) {
                console.log(response);
                window.FB.logout(function (response1) {
                  console.log(response1);
                  console.log("Logged out");
                  document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                  window.location = api.server + "/login?lang=" + lang;
                });
              } else {
                document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                window.location = api.server + "/login?lang=" + lang;
              }
            });
          }
        }
      );
    } else if (event.name === "addDevice") {
      api
        .createDevicein(
          sessionId,
          event.device.name,
          event.device.type,
          event.device.deviceId,
          event.device.groupId
        )
        .then(
          function (data) {
            console.log(data.data);
            this.mynestEvent({ name: "getInfo" });
          }.bind(this)
        );
    } else if (event.name === "getEvents") {
      api
        .getEvents(
          sessionId,
          event.from,
          event.to,
          event.deviceId
        )
        .then(
          function (data) {
            this.mynestEvent({ name: "getInfo" });
          }.bind(this)
        );
    } else if (event.name === "deleteDevice") {
      api
        .deleteDevice(sessionId, event.device.deviceId, event.device.groupId)
        .then(
          function (data) {
            console.log(data.data);
            this.mynestEvent({ name: "getInfo" });
          }.bind(this)
        );
    } else if (event.name === "editDevice") {
      api
        .updateDevice(sessionId,
          event.device.name,
          event.device.type,
          event.device.deviceId,
          event.device.groupId,
          event.device.initialGroupId)
        .then(
          function (data) {
            console.log(data.data);
            this.mynestEvent({ name: "getInfo" });
          }.bind(this)
        );
    } else if (event.name === "controlDevice") {
      console.log(event);

      api
        .controlDevice(sessionId,
          event.directive,
          event.device.deviceId)
        .then(
          function (data) {
            console.log(data.data);
            this.mynestEvent({ name: "getInfo" });
          }.bind(this)
        );
    } else if (event.name === "addGroup") {
      api
        .createGroup(
          sessionId,
          event.group.name
        )
        .then(
          function (data) {
            console.log(data.data);
            this.mynestEvent({ name: "getInfo" });
          }.bind(this)
        );
    } else if (event.name === "updateGroup") {
      api
        .updateGroup(
          sessionId,
          event.group.name,
          event.group.groupId
        )
        .then(
          function (data) {
            console.log(data.data);
            this.mynestEvent({ name: "getInfo" });
          }.bind(this)
        );
    } else if (event.name === "deleteGroup") {
      api
        .deleteGroup(
          sessionId,
          event.group.groupId
        )
        .then(
          function (data) {
            console.log(data.data);
            this.mynestEvent({ name: "getInfo" });
          }.bind(this)
        );
    }
  };
  addGroupClicked = () => {
    const { addingGroup } = this.state;
    if (!addingGroup) {
      this.setState({ addingGroup: true });
    }
  };
  okClicked = () => {
    const { addGroupName } = this.state;
    this.mynestEvent({ name: "addGroup", group: { name: addGroupName } })
  };
  cancelClicked = () => {
    const { addingGroup } = this.state;
    if (addingGroup) {
      this.setState({ addingGroup: false });
    }
  };
  nameChanged = value => {
    this.setState({ addGroupName: value })
  };

  render() {
    const { groups, devices, timezone, plan, loaded } = this.state;
    const { lang, signout, admin } = this.props;

    if (!loaded) {
      return this.renderLoadingScreen();
    }
    else {

      var groupDevices = {};
      var subscriptionInfo = plan;
      if (subscriptionInfo) subscriptionInfo.devices = devices.length;
      else { subscriptionInfo = { type: "Basic", validUntil: "", devices: devices.length } }
      if (signout) {
        this.mynestEvent({ name: "signout" });
      }
      for (let i = 0; i < groups.length; i++) {
        groupDevices[groups[i]._id] = [];
        for (let j = 0; j < devices.length; j++) {
          const dev = devices[j];
          for (let k = 0; k < groups[i].devices.length; k++) {
            const devListItem = groups[i].devices[k];
            if (dev._id === devListItem) groupDevices[groups[i]._id].push(dev);
          }
        }
      }
      return (
        <div className="mynest-wrapper">
          <h4>{local.translate("mydevices", lang)}</h4>
          <h6 id="deviceCount">
            {local.translate("groups", lang)}: {groups.length}{" "}
            {local.translate("devices", lang)}: {devices.length}
          </h6>
          <div id="Groups">
            {groups.map(gr => (
              <Group
                view="card"
                admin={admin}
                key={gr._id}
                name={gr.name}
                lang={lang}
                devices={groupDevices[gr._id]}
                groups={groups}
                onEvent={this.mynestEvent}
                groupId={gr._id}
                users={gr.users}
                timezone={timezone}
                plan={subscriptionInfo}
              />
            ))}
          </div>
          <div id="No groups">
            <Group
              view="card"
              admin={admin}
              key="nogroup"
              groupId="nogroup"
              name={local.translate("nogroup", lang)}
              lang={lang}
              devices={this.getdevicesnogroup()}
              groups={groups}
              onEvent={this.mynestEvent}
              users={[]}
              timezone={timezone}
              plan={subscriptionInfo}
            />
          </div>
          {this.getAddGroupSection()}
        </div>
      );

    }

  }

  renderLoadingScreen() {
    return (
      <div className="loading-div animated bounceIn">
        <img src="img/loading.png" alt="loading" />
      </div>
    )
  }

  componentDidMount() {
    this.mynestEvent({ name: "getInfo" });
    refreshInterval = setInterval(this.mynestEvent, 3000, { name: "getInfo" });
  }

  componentWillUnmount() {
    clearInterval(refreshInterval);
  }

  getdevicesnogroup() {
    const { devices, groups } = this.state;
    var nogroupDevices = [];

    for (let j = 0; j < devices.length; j++) {
      const dev = devices[j];
      var found = false;
      for (let i = 0; i < groups.length; i++) {
        const gr = groups[i];
        for (let k = 0; k < gr.devices.length; k++) {
          const devListItem = groups[i].devices[k];
          if (dev._id === devListItem) found = true;
        }
      }
      if (!found) nogroupDevices.push(dev);
    }
    return nogroupDevices;
  }

  getAddGroupSection() {
    const { addingGroup } = this.state;
    const { lang } = this.props;
    if (addingGroup) {
      return (
        <div className="add-group-div">
          <input
            maxLength="31"
            className="new-group-title"
            placeholder={local.translate("groupname", lang)}
            onChange={event => this.nameChanged(event.target.value)}
          ></input>
          <div className="add-group-btns-wrapper ">
            <button
              onClick={this.okClicked}
              className="group-btn"
            >
              <ion-icon name="checkmark"></ion-icon> OK
            </button>
            <button
              onClick={this.cancelClicked}
              className="group-btn"
            >
              <ion-icon name="close"></ion-icon>
              {local.translate("cancel", lang)}
            </button>
          </div>
        </div>
      );
    } else
      return (
        <div className="add-group-div animated bounceIn" onClick={this.addGroupClicked}>
          <h3>
            <ion-icon name="add"></ion-icon> {local.translate("addgroup", lang)}
          </h3>
        </div>
      );
  }




}

export default Devices;
