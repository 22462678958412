import React, { useCallback, useEffect, useState } from 'react';
import { Chart, ArcElement, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'
import { Line } from 'react-chartjs-2';
Chart.register(ArcElement);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(CategoryScale);
Chart.register(LinearScale);
var api = require("../../../utils/api.js");

export default function SorterShiftChart(props) {
  const { sorterId, sessionId } = props;
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const [sorterDevice, setSorterDevice] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [personCount, setPersonCount] = React.useState(0);
  const [hourlyEvents, setHourlyEvents] = React.useState([null, null, null, null, null, null, null, null]);
  const [hourlyTotals, setHourlyTotals] = React.useState([null, null, null, null, null, null, null, null]);
  const [hours, setHours] = React.useState([8, 9, 10, 11, 12, 13, 14, 15]);
  const [shift, setShift] = React.useState("");
  const [shiftStart, setShiftStart] = React.useState();

  const getHourCount = useCallback(async (initialTimestamp, finalTimestamp) => {
    // console.log("Getting data from", initialTimestamp, "to", finalTimestamp);
    let response = await api.getEvents(sessionId, initialTimestamp, finalTimestamp, sorterId, undefined, undefined, true);
    if (!sorterDevice.id) {
      let device = response.data.device;
      setSorterDevice(device);
      setPersonCount(device.personCount ?? 0);
    }
    // console.log("Hourly count is", response.data?.count ?? 0);
    return response.data?.count ?? 0;

  }, [sessionId, sorterDevice.id, sorterId])

  const getData = useCallback(async (initialShiftStart, initialHours) => {
    let currentShiftStart = initialShiftStart ?? shiftStart;
    let currentHours = initialHours ?? hours;


    setLoading(true);
    let now = new Date();

    let currentHourIndex = currentHours.indexOf(now.getHours())

    let newHourlyEvents = [...hourlyEvents]
    let newHourlyTotals = [...hourlyTotals]
    let sum = 0;
    for (let index = 0; index < 8; index++) {
      let hourInitialTimestamp = currentShiftStart.getTime() + (index * 3600000)
      let finalTimestamp = hourInitialTimestamp + 3600000
      let isInThePast = hourInitialTimestamp < now.getTime()
      let isUnknownValue = newHourlyEvents[index] === null
      if ((isInThePast && isUnknownValue) || (index === currentHourIndex)) {
        let count = await getHourCount(hourInitialTimestamp, finalTimestamp)
        newHourlyEvents[index] = count
        sum = sum + count
        newHourlyTotals[index] = sum
        setHourlyEvents(newHourlyEvents)
        setHourlyTotals(newHourlyTotals)
      }
    }
    setLastUpdated(new Date());
    setLoading(false);

  }, [getHourCount, hourlyEvents, hourlyTotals, hours, shiftStart])

  const setupShift = useCallback(async () => {
    let now = new Date();
    let hour = now.getHours();
    let currentShift = (hour >= 7 && hour < 15) ? "AM" : (hour >= 15 && hour < 23) ? "PM" : "Nocturno";
    if (currentShift !== shift) {
      let shiftStartHour = (currentShift === "AM") ? 7 : (currentShift === "PM") ? 15 : 23;
      let shiftStartDayOffset = (currentShift === "Nocturno" && hour !== 23) ? 1 : 0;
      let shiftStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - shiftStartDayOffset, shiftStartHour, 0, 0);

      let newHours = [0, 0, 0, 0, 0, 0, 0, 0]
      for (let index = 0; index < 8; index++) {
        let h = shiftStartHour + index
        if (h < 24) {
          newHours[index] = h
        } else {
          newHours[index] = h - 24
        }
      }

      setShift(currentShift);
      setHourlyEvents([null, null, null, null, null, null, null, null]);
      setHourlyTotals([null, null, null, null, null, null, null, null]);
      setHours(newHours)
      setShiftStart(shiftStart);
      getData(shiftStart, newHours);
    }
  }, [shift, getData])


  useEffect(() => {
    const interval = setInterval(getData, 300000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  useEffect(() => {
    setupShift();
    const interval = setInterval(setupShift, 1000);
    return () => clearInterval(interval);
  }, [setupShift]);

  const data = {
    labels: hours,
    type: 'line',
    datasets: [
      {
        label: 'Dataset 1',
        data: hourlyTotals,
        borderColor: 'rgb(75, 192, 192)',

      }
    ]
  };

  return <div className='m-5'>
    <Line data={data} />
    <table className="table table-bordered">
      <thead>
        <tr>
          <th scope="col"> {sorterDevice?.name} </th>
          <th scope="col"> </th>
          <th scope="col">  {loading && <img className='loading-animate' src="img/loading.png" alt="loading" />}  </th>
        </tr>
      </thead>
      <tbody>
        {hours.map((hour, index) => {

          return <tr key={index}>
            <td>{hour}</td>
            <td>{hourlyEvents[index]}</td>

            {hourlyEvents[index] ? <td>{personCount !== 0 ? (hourlyEvents[index] / personCount).toFixed(0) : 0}</td> : <td></td>}
          </tr>
        })}
      </tbody>
    </table>

    <p style={{ color: "grey", fontSize: 12 }} >Actualizado: {lastUpdated.toLocaleTimeString()}</p>
  </div >


}

