import React from 'react';
import "./server_time.css"

export default function ServerTime({ offset, loading, onChange }) {
  const now = new Date();
  const utcHours = now.getUTCHours();
  const hours = utcHours - offset >= 0 ? utcHours - offset : 24 + utcHours - offset;
  const minutes = now.getUTCMinutes() > 10 ? now.getUTCMinutes() : "0" + now.getUTCMinutes();

  console.log(utcHours);
  return <div className="dashboard-card animate__animated animate__bounceInDown">
    <div className="d-flex"><i className="fa fa-microchip"></i>
      <h5 className="text-center insight-title">Hora del Servidor</h5>
    </div>
    {loading ? <div className="animate__animated animate__backInLeft">
      <img src="img/loading.png" alt="loading" width={30} height={30} />
    </div> : <div className="time-container">
      <input type="number" value={hours} onChange={(e) => {
        let val = Number(e.target.value);
        if (val < 0) val += 24;
        if (val >= 24) val -= 24;
        console.log("val:", val);
        onChange(utcHours - val)
      }} />
      <>:</>
      <input type="text" value={minutes} />
    </div>}
  </div>
}