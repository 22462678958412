module.exports = {
    translate: translate
}
  

function translate(expresion, lang) {
    if (lang !== "EN" && lang !== "ES" && lang !== "DE" && lang !== "CZ" && lang !== "FR"&& lang !== "PT" && lang !== "IT" && lang !== "JP") {
      lang = "EN";
    }
    var libr = {
      nogroup:{EN:"No group",ES:"Otros",DE:"keine Gruppe",CZ:"žádná skupina",FR:"Aucun groupe",PT:"nenhum grupo",IT:"Nessun gruppo",JP:"ませんグループ",},
      mydevices:{EN:"My devices",ES:"Mis dispositivos",DE:"Meine Geräte",CZ:"Moje zařízení",FR:"Mes appareils",PT:"Meus dispositivos",IT:"I miei dispositivi",JP:"マイデバイス",},
      groups:{EN:"Groups",ES:"Grupos",DE:"Gruppen",CZ:"Skupiny",FR:"Groupes",PT:"grupos",IT:"gruppi",JP:"グループ",},
      devices:{EN:"Devices",ES:"Dispositivos",DE:"Geräte",CZ:"Zařízení",FR:"Dispositifs",PT:"Devices",IT:"dispositivi",JP:"デバイス",},
      addgroup:{EN:"Add group",ES:"Añadir grupo",DE:"Gruppe hinzufügen",CZ:"Přidat skupinu",FR:"Ajouter un groupe",PT:"Adicionar grupo",IT:"Aggiungere gruppo",JP:"[追加]グループ",},
      groupname:{EN:"Group name",ES:"Nombre del grupo",DE:"Gruppenname",CZ:"Název skupiny",FR:"Nom de groupe",PT:"Nome do grupo",IT:"Nome del gruppo",JP:"グループ名",},
      cancel:{EN:"Cancel",ES:"Cancelar",DE:"Stornieren",CZ:"Zrušit",FR:"Annuler",PT:"Cancelar",IT:"Annulla",JP:"キャンセル",},
      verifyemailalert:{EN:"Please verify your e-mail address by clicking the link that was sent to you, if you cannot see it try checking Spam folder",ES:"Por favor verifica tu dirección de correo electrónico haciendo clic en el enlace que te enviamos, si no ves el correo verifica en la carpeta de correo no deseado",DE:"Bitte überprüfen Sie Ihre E-Mail-Adresse, indem Sie auf den Link klicken, der an Ihre E-Mail-Adresse gesendet wurde, wenn Sie das E-mail nicht finden können, Suchen Sie im Spam-Ordner",CZ:"Ověřte svou e-mailovou adresu kliknutím na odkaz, který byl odeslán na váš e-mail, pokud jej nevidíte, zkuste zkontrolovat složku Spam",FR:"S'il vous plaît vérifier votre adresse e-mail en cliquant sur le lien qui vous a été envoyé, si vous ne pouvez pas le voir essayer de vérifier le dossier Spam",PT:"Por favor, verifique seu endereço de e-mail clicando no link que foi enviado para você, se você não pode vê-lo tentar verificar pasta Spam",IT:"Si prega di verificare il tuo indirizzo e-mail cliccando sul link che è stato inviato a te, se non si può vedere provare a controllare la cartella Spam",JP:"あなたはそれが迷惑メールフォルダをチェックしてみてください見ることができない場合は、あなたに送られたリンクをクリックしてメールアドレスを確認してください",},
    };

    if (libr[expresion] == null) return "ERROR";
    if (libr[expresion][lang] == null) return "ERROR";
    return libr[expresion][lang];
  }