import React, { Component } from 'react';
import '../../css/lampDetails.css'
var api = require("../../../utils/api-v2");


class BoardDetails extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: false, name: props.device.name, locationUrl: props.device.locationUrl, groupId: props.device.groupId }
	}
	render() {
		const { device } = this.props
		const { editing } = this.state
		let img = "board.svg"

		if (device.alarm !== 0) {
			img = "board_alarm.svg"
		}

		return (
			<div className='detail-comtainer'>
				<div className="device-name-title">{device.name}</div>
				<div className="device-status-img">
					<div> <img src={"/img/icons/" + img} alt="status" width="60" height="60" /></div>
				</div>
				{editing ? this.renderEdit() : this.renderNormal()}

			</div>
		);
	}

	renderNormal() {
		const { device } = this.props

		let actionButtonClicked = () => {
			api.updateDevice(device._id, { "alarm": 0 })
		}
		const lastConnectionDate = new Date(device.lastConnection);

		return (
			<div>
				<div >
					<div className="d-flex justify-content-between my-3">
						<div className="d-flex">
							<div className='mx-2'><img src="/img/device-features/people.svg" alt="status" height="20" width="20" /></div>
							<div>Usuarios:</div>
						</div>
						<div>{device.visitors ?? 0}</div>
					</div>
					<div className="d-flex justify-content-between my-3">
						<div className="d-flex">
							<div className='mx-2'><img src={"/img/device-features/" + (device.alarm ? "alert-on.svg" : "alert-off.svg")} alt="status" height="20" width="20" /></div>
							<div>Alarma:</div>
						</div>
						<div>{device.alarm ?? "No"}</div>
					</div>
					<div className="d-flex justify-content-between my-3">
						<div className="d-flex">
							<div className='mx-2'><img src="/img/device-features/board.svg" alt="status" height="20" width="20" /></div>
							<div>Contenido:</div>
						</div>
						<div>{device.content ?? "----"}</div>
					</div>
					<div className="d-flex justify-content-between my-3">
						<div className="d-flex">
							<div className='mx-2'><img src="/img/device-features/cloud_link.svg" alt="status" height="20" width="20" /></div>
							<div>Ultima conexión:</div>
						</div>
						<div style={{ textAlign: "end", fontSize: "12px" }}>{device.lastConnection ? (`${lastConnectionDate.toLocaleDateString()} ${lastConnectionDate.toLocaleTimeString()}`) : "Nunca"}</div>
					</div>
				</div>
				{device.alarm !== 0 && <div className="d-flex justify-content-around my-3">
					<div className="btn btn-secondary" onClick={actionButtonClicked}>{"Remover alerta"}</div>
				</div>}
				<div className="d-flex justify-content-around my-3">
					<div className="text-btn" onClick={() => { this.setState({ editing: true }) }}>Editar</div>
				</div>

			</div>
		)
	}

	renderEdit() {
		const { groups, device, onClose } = this.props
		const { name, locationUrl, groupId } = this.state

		let doneClicked = () => {
			api.updateDevice(device._id, { name: name, locationUrl: locationUrl, groupId: groupId }, groupId, device.groupId)
			this.setState({ editing: false });
		}
		let deleteClicked = () => {
			if (window.confirm("Eliminar Dispositivo " + name + "?")) {
				api.deleteDevice(device._id);
				onClose();
			}
		}

		return (
			<div className='detail-edit-form'>
				<div className="d-flex justify-content-between my-2">
					<div >ID:</div>
					<div >{device._id}</div>
				</div>
				<div className="d-flex justify-content-between my-2">
					<div >Nombre</div>
					<div ><input type="text" onChange={(e) => { this.setState({ name: e.target.value }) }} value={name} /></div>
				</div>
				<div className="d-flex justify-content-between my-2">
					<div >Ubicación</div>
					<div ><input type="text" onChange={(e) => { this.setState({ locationUrl: e.target.value }) }} value={locationUrl} /></div>
				</div>
				<div className="d-flex justify-content-between my-2">
					<div >Grupo</div>
					<div ><select onChange={(e) => { this.setState({ groupId: e.target.value }) }} value={device.groupId}>
						{groups.map((gr) => <option value={gr._id}>{gr.name}</option>)}
					</select></div>
				</div>
				<div className="detail-edit-buttons">
					<button className="btn btn-secondary" onClick={doneClicked}>OK</button>
					<button className="btn btn-danger" onClick={deleteClicked}>Eliminar</button>
				</div>
			</div>

		)
	}
}

export default BoardDetails;