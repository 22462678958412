import React, { Component } from 'react';
import "../css/login.css";

let loginBtnTxt = "Iniciar Sesión"
let signupBtnTxt = "Crear Usuario"
let stylePw2 = "none"
let stylePw1 = ""
let styleEmail = "none"


class Login extends Component {
    state = {
        usernameTyped: "",
        emailTyped: "",
        passwordTyped: "",
        password2Typed: "",
        signupMode: false,
        recoverPwMode: false,
    }

    usernameChanged = (value) => {
        this.setState({ usernameTyped: value });
    }
    emailChanged = (value) => {
        this.setState({ emailTyped: value });
    }
    passwordChanged = (value) => {
        this.setState({ passwordTyped: value });

    }
    password2Changed = (value) => {
        this.setState({ password2Typed: value });

    }

    loginClicked = () => {
        const { setCookie, api } = this.props
        const { usernameTyped, passwordTyped, password2Typed, emailTyped, signupMode, recoverPwMode } = this.state;
        let resp;
        if (signupMode) {
            if (passwordTyped !== "" && passwordTyped === password2Typed && emailTyped !== "") {

                api.signup(usernameTyped, passwordTyped, password2Typed, emailTyped).then(
                    function (data) {
                        resp = data.data;
                        if (resp.result === "success" && resp.sessionId) {
                            setCookie(resp.sessionId);
                        } else if (resp.errorCode === "usernameinuse") {
                            window.alert("Usuario ya existe");
                        } else if (resp.errorCode === "emailinuse") {
                            window.alert("Email ya existe");
                        }

                    })
            } else {
                window.alert("Las contraseñas no coinciden");
            }
        } else if (!recoverPwMode) {
            api.login(usernameTyped, passwordTyped).then(
                function (data) {
                    resp = data.data;

                    if (resp.result === "success" && resp.sessionId) {
                        this.setState({ signupMode: true });
                        setCookie(resp.sessionId);
                    } else if (resp.errorCode === "wrongcredentials") {
                        window.alert("Usuario o contraseña incorrectos");
                    }
                }.bind(this))
        } else if (recoverPwMode) {
            api.adminPasswordReset(emailTyped).then(
                function (data) {
                    resp = data.data;

                    if (resp.result) {
                        window.alert(resp.result);

                    } else {
                        window.alert("Si el email se encuentra en nuestra base de datos, se enviará un correo electrónico con las instrucciones para reestablcer la contraseña");
                    }
                })

        }



    };

    signupClicked = () => {

        const { signupMode, recoverPwMode } = this.state;
        if (signupMode || recoverPwMode) {
            this.setState({ signupMode: false, recoverPwMode: false });
            loginBtnTxt = "Iniciar Sesión";
            signupBtnTxt = "Crear Usuario";
            stylePw2 = "none";
            stylePw1 = "";
            styleEmail = "none"
        }
        else {
            this.setState({ signupMode: true });
            loginBtnTxt = "Crear Usuario";
            signupBtnTxt = "Cancelar";
            stylePw2 = "";
            stylePw1 = "";
            styleEmail = "";
        }
    };

    passForgotenClicked() {


        this.setState({ signupMode: false, recoverPwMode: true });
        loginBtnTxt = "Recuperar Contraseña";
        signupBtnTxt = "Cancelar";
        stylePw1 = "none";
        stylePw2 = "none";
        styleEmail = "";


    }

    componentDidMount() {
        const { getCookie, setCookie } = this.props;
        let sessionId = getCookie("sessionId")
        if (sessionId !== "") {
            setCookie(sessionId);
        }
    }

    render() {


        return (
            <div className="login-clean App">

                <div className="form animated pulse">
                    <div className=""><img className="logo" width="128" height="128" src="/img/logo.png" alt="logo" /></div>
                    <img id="title" src="/img/title.png" width="212" height="43" alt="logo" />

                    <input type="text" className="form-field" name="username" inputMode="verbatim" required style={{ display: stylePw1 }}
                        pattern="[A-Za-z0-9.-]+" minLength="6" maxLength="30" placeholder="Usuario" title="Solo Caracteres A-z a-z 0-1 -." onChange={event => this.usernameChanged(event.target.value)} />

                    <input type="email" className="form-field" id="email" name="email" style={{ display: styleEmail }} inputmode="verbatim" autocomplete="on" autofocus
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder="E-mail" title="E-mail" onChange={event => this.emailChanged(event.target.value)} />

                    <input type="password" className="form-field" name="password" required pattern="(?=.*\d)(?=.*[a-z]).{8,}" minLength="8" style={{ display: stylePw1 }}
                        placeholder="Contraseña" title="Minimo 8 caracteres 1 letra y 1 número" onChange={event => this.passwordChanged(event.target.value)} />

                    <input type="password" id="password2" className="form-field" name="password2" required pattern="(?=.*\d)(?=.*[a-z]).{8,}" minLength="8"
                        placeholder="Confirmar contraseña" title="Minimo 8 caracteres 1 letra y 1 número" style={{ display: stylePw2 }} onChange={event => this.password2Changed(event.target.value)} />

                    <button className="form-btn" onClick={this.loginClicked}> {loginBtnTxt} </button>
                    <button className="form-btn" onClick={this.signupClicked}> {signupBtnTxt}</button>

                    <h6 className="forgotPw" onClick={this.passForgotenClicked.bind(this)}>¿Ha olvidado su contraseña?</h6>
                </div>
                <a className='email-box' href={"mailto:info@smartage.cl"}>
                    <div style={{ width: "40px", marginRight: "10px" }}><img src="/img/icons/email.svg" width="40" height="40" alt="logo" /></div>
                    <div style={{ width: "200px" }}>
                        <div>Preguntas y soporte:</div>
                        <div>info@smartage.cl</div>
                    </div>
                </a>

            </div>
        )
    }

}

export default Login;