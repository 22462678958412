import React, { Component } from "react";
import Devices from "./Pages/Devices";
import Admin from "./Pages/Admin";
import Login from "./Pages/Login";
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import StreetLight from "./Pages/StreetLight";
import Licences from "./Pages/Licences";
import ReportsPage from "./Pages/Reports";
import LcdBoards from "./Pages/LcdBoards";
import Spatial from "./Pages/Spatial";
import MassReportsPage from "./Pages/MassReports";
import ProductivityPage from "./Pages/Productivity";

var api = require("../utils/api");


class App extends Component {

  constructor() {
    super();
    let sId = this.getCookie("sessionId");
    this.state = {
      lang: "ES",
      page: "devices",
      timezone: "GMT-03",
      admin: false,
      streetLightView: false,
      spatialView: false,
      lcdBoardView: false,
      reportsView: false,
      sessionId: sId
    }
  }

  onSignOut = () => {
    this.setState({ admin: false, page: "devices", streetLightView: false, spatialView: false, lcdBoardView: false });

  }

  adminMode = (type) => {
    this.setState({ admin: type });
  }

  streetLightMode = () => {
    const { page, admin } = this.state
    this.setState({ streetLightView: true });
    if (page === "devices" && !admin)
      this.setState({ page: "streetLight" });
  }

  lcdBoardsMode = () => {
    const { page, admin } = this.state
    this.setState({ lcdBoardView: true });
    if (page === "devices" && !admin)
      this.setState({ page: "lcdBoards" });
  }

  spatialMode = () => {
    const { page, admin } = this.state
    this.setState({ spatialView: true });
    if (page === "devices" && !admin)
      this.setState({ page: "spatial" });
  }

  reportsMode = () => {
    this.setState({ reportsView: true });
  }

  setCookie = (sessionId, language = "ES") => {
    var d = new Date();
    d.setTime(d.getTime() + (10 * 24 * 60 * 60 * 1000));//10 days
    var expires = "expires=" + d.toUTCString();
    document.cookie = "sessionId=" + sessionId + ";" + expires + ";path=/";
    document.cookie = "lang=" + language + ";" + expires + ";path=/";
    this.setState({ sessionId: sessionId })
  }

  getCookie(cookieName) {
    var name = cookieName + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  tabClicked = (pageClicked) => {
    this.setState({ page: pageClicked })
    this.resetNavBar();

  }

  renderPage() {
    const { page, lang, sessionId, admin, timezone } = this.state;
    if (page === "devices") {
      return <Devices lang={lang} sessionId={sessionId} onAdmin={this.adminMode} onStreetLight={this.streetLightMode} onLcdBoardsMode={this.lcdBoardsMode} onSpatialMode={this.spatialMode} onReportsMode={this.reportsMode} setCookie={this.setCookie} admin={admin} timezone={timezone} />;
    } else if (page === "admin") {
      return <Admin lang={lang} sessionId={sessionId} admin={admin} setCookie={this.setCookie} timezone={timezone} />;
    } else if (page === "streetLight") {
      return <StreetLight setCookie={() => { console.log("settting cookie") }} />;
    } else if (page === "lcdBoards") {
      return <LcdBoards setCookie={() => { console.log("settting cookie") }} />;
    } else if (page === "licences") {
      return <Licences setCookie={() => { console.log("settting cookie") }} />;
    } else if (page === "spatial") {
      return <Spatial setCookie={() => { console.log("settting cookie") }} />;
    } else if (page === "reports") {
      return <ReportsPage sessionId={sessionId} />;
    } else if (page === "massReports") {
      return <MassReportsPage sessionId={sessionId} />;
    } else if (page === "productivity") {
      return <ProductivityPage sessionId={sessionId} />;
    }


  }

  resetNavBar() {
    if (document.getElementById("navcol-1"))
      document.getElementById("navcol-1").className = "collapse navbar-collapse flex-grow-0 justify-content-end";
  }

  render() {
    const { lang, page, admin, sessionId, streetLightView, lcdBoardView, spatialView, reportsView } = this.state;
    if (sessionId !== "")
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <NavBar lang={lang} page={page} onTabClicked={this.tabClicked} admin={admin} lcdBoardView={lcdBoardView} streetLightView={streetLightView} spatialView={spatialView} reportsView={reportsView} setCookie={this.setCookie} sessionId={sessionId} api={api} onSignOut={this.onSignOut} />
          {this.renderPage()}
          <Footer lang={lang}></Footer>
        </div>
      );
    else
      return (

        <div>
          <Login setCookie={this.setCookie} getCookie={this.getCookie} api={api} />;
          <Footer lang={lang}></Footer>
        </div>

      )
  }
}

export default App;
