import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import '../css/map.css'

const center = { lat: -33.4561387, lng: -70.6615389 };

class DeviceMap extends Component {
  state = {
    deviceSelected: {},
    deviceHover: ""
  }

  renderMarker(dev) {
    const { google, menuItemHoverId, onMarkerClicked } = this.props
    const { deviceHover } = this.state

    let markerClicked = (details) => {
      onMarkerClicked(details.id)
    }

    let markerMouseOver = (details) => {
      if (details.id !== deviceHover)
        this.setState({ showInfo: true, deviceHover: details.id })
    }

    let img = "";
    if (dev.type === "light") {
      img = "street_lamp_off.png";
      if (!dev.connected) img = "street_lamp_offline.png"
      else if (dev.powerState === "ON" && dev.percentage === 0) img = "street_lamp_error.png"
      else if (dev.powerState === "ON") img = "street_lamp_on.png"
    } else {
      img = "board.svg";
      if (dev.alarm !== 0) img = "board_alarm.svg"
    }

    let slct = (dev._id === deviceHover || dev._id === menuItemHoverId)

    return <Marker
      key={dev._id}
      label={{ text: dev.name, className: (slct ? "map-lbl" : "d-none") }}
      position={{ lat: dev.lat, lng: dev.lng }}
      icon={{
        url: '/img/icons/' + img,
        labelOrigin: new google.maps.Point(0, -10),
        scaledSize: new google.maps.Size(slct ? 50 : 40, slct ? 50 : 40)
      }}
      id={dev._id}
      onClick={markerClicked}
      onMouseover={markerMouseOver}
    />
  }

  render() {
    const { google, devices } = this.props

    let mapDraged = (details) => { this.setState({ showInfo: false, deviceHover: "" }) }



    return (
      <div className="my-map">
        <Map google={google} zoom={12} onDragstart={mapDraged}
          initialCenter={{ lat: center.lat, lng: center.lng }} style={{ width: window.innerWidth, height: window.innerHeight - 65 }}>
          {devices.map((dev) => this.renderMarker(dev))}

        </Map>
      </div>)

  }
}


export default GoogleApiWrapper({
  //apiKey: "AIzaSyB7V2sa4OFAvsSBlR9ogw_3fmihEc3PK-c",
  apiKey: "AIzaSyBzfRl06V4QfgCrwK3diW4mKaHWl-fLZxM",
  language: 'es'
})(DeviceMap);