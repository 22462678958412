import React, { useEffect } from "react";

var api = require("../../../../utils/api.js");


export default function Conveyor(props) {
  const { device } = props;
  const [events, setEvents] = React.useState([]);
  const [shift, setShift] = React.useState("AM");

  useEffect(() => {
    let now = new Date();
    let hour = now.getHours();
    let shift = (hour >= 7 && hour < 15) ? "AM" : (hour >= 15 && hour < 23) ? "PM" : "Nocturno";
    setShift(shift)
    let shiftStartHour = (shift === "AM") ? 7 : (shift === "PM") ? 15 : 23;
    let shiftStartDayOffset = (shift === "Nocturno" && hour !== 23) ? 1 : 0;
    let shiftStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - shiftStartDayOffset, shiftStartHour, 0, 0);
    console.log(shiftStart.toUTCString());

    api.getEvents("token", shiftStart.getTime(), now.getTime(), device._id, 0, 25200).then(function (response) {
      if (response.data) { setEvents(response.data.events); }
    });
  }, [device._id, device.deviceId]);

  let src = "img/device-features/conveyor.png"
  let startEvents = events.filter(event => event.event === "start");
  let timeIdle = (startEvents.reduce((acc, event) => acc + (event.v ?? 0), 0)) / 60;
  let timeIdleHours = timeIdle / 60;
  let lastEvent = startEvents.at(startEvents.length - 1);
  return (
    <div style={{ marginBottom: "10px" }}>
      <div className="row device-feature">
        <div className="col-4">
          <img src={src} alt="toogle on" width={50} height={50} />
        </div>
        <div className="col-8">
          <h3 className="feature-value-title">Total: {timeIdleHours.toFixed(1)} h</h3>
          <h3 className="feature-value-title">Total: {timeIdle.toFixed(0)} min</h3>
          <h3 className="feature-value-title">Turno {shift}</h3>
        </div>
      </div>
      <div>
        <h5 className="feature-value-lbl" >Parciales:</h5>
        <h6 className="feature-value-lbl" >Último reporte: {((lastEvent?.v ?? 0) / 60).toFixed(1)} min</h6>

      </div>
    </div >

  )

};
