var axios = require("axios");

module.exports = {
	getSessionInfo2: function () {
		var encodedURI = window.encodeURI("/api/v2/user_info/");
		return axios.get(encodedURI).then(function (response) {
		  return response;
		});
	},
	addSection:function (name, level, parent) {
		var encodedURI = window.encodeURI("/api/v2/add/section");
		var body = {name:name, level:level, parent:parent};
		return axios.post(encodedURI, body).then(function (response) {
		  return response;
		});
	},
	updateSection:function (id, update) {
		var encodedURI = window.encodeURI("/api/v2/update/section");
		var body = {id:id, update:update};
		return axios.post(encodedURI, body).then(function (response) {
		  return response;
		});
	},
	deleteSection:function (id) {
		var encodedURI = window.encodeURI("/api/v2/section/"+id);
		return axios.delete(encodedURI).then(function (response) {
		  return response;
		});
	},
	addGroup:function (name, parent) {
		var encodedURI = window.encodeURI("/api/v2/add/group");
		var body = {name:name, parent:parent};
		return axios.post(encodedURI, body).then(function (response) {
		  return response;
		});
	},
	updateGroup:function (id, update) {
		var encodedURI = window.encodeURI("/api/v2/update/group");
		var body = {id:id, update:update};
		return axios.post(encodedURI, body).then(function (response) {
		  return response;
		});
	},
	deleteGroup:function (id) {
		var encodedURI = window.encodeURI("/api/v2/group/"+id);
		return axios.delete(encodedURI).then(function (response) {
		  return response;
		});
	},
	addDevcie:function (name, type, groupId) {
		var encodedURI = window.encodeURI("/api/v2/add/device");
		var body = {name:name, type:type, groupId:groupId};
		return axios.post(encodedURI, body).then(function (response) {
		  return response;
		});
	},
	
	updateDevice:function (id, update, groupId, groupIdOld) {
		var encodedURI = window.encodeURI("/api/v2/update/device");
		var body = {id:id, update:update,groupIdOld:groupIdOld, groupId:groupId};
		return axios.post(encodedURI, body).then(function (response) {
		  return response;
		});
	},
	
	controlDevice:function (id, directive, value) {
		var encodedURI = window.encodeURI("/api/v2/control/device");
		var body = {id:id, directive:directive,value:value};
		return axios.post(encodedURI, body).then(function (response) {
		  return response;
		});
	},

	deleteDevice:function (id) {
		var encodedURI = window.encodeURI("/api/v2/device/"+id);
		return axios.delete(encodedURI).then(function (response) {
		  return response;
		});
	},

	addRoutine:function (type, id, directive, value, time) {
		var encodedURI = window.encodeURI("/api/v2/add/routine");
		var body = {type:type, id:id, directive:directive, value:value, time:time};
		return axios.post(encodedURI, body).then(function (response) {
		  return response;
		});
	},

	deleteRoutine:function (id) {
		var encodedURI = window.encodeURI("/api/v2/routine/"+id);
		return axios.delete(encodedURI).then(function (response) {
		  return response;
		});
	},
}