var axios = require("axios");

module.exports = {

  login: function (username, password) {
    var encodedURI = window.encodeURI("/api/v1/login");
    var body = {
      username: username,
      password: password
    };
    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  signup: function (username, password, password2, email) {
    var encodedURI = window.encodeURI(
      "/api/v1/login"
    );

    var body = {
      username: username,
      password: password,
      password2: password2,
      name: "",
      email: email
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  signout: function (sessionId) {
    var encodedURI = window.encodeURI(
      "/api/v1/signout/" + sessionId
    );

    return axios.post(encodedURI).then(function (response) {
      return response;
    });
  },
  getSessionInfo: function (sessionId) {
    var encodedURI = window.encodeURI(
      "/api/v1/sessions/" + sessionId
    );

    return axios.post(encodedURI).then(function (response) {
      return response;
    });
  },
  getAdminInfo: function (sessionId) {
    var encodedURI = window.encodeURI(
      "/api/v1/getAdminInfo/" + sessionId
    );

    return axios.post(encodedURI).then(function (response) {
      return response;
    });
  },

  setServerTimeOffset: function (offset) {
    var encodedURI = window.encodeURI(
      "/api/v1/admin/set_offset"
    );

    return axios.post(encodedURI, { offset }).then(function (response) {
      return response;
    });
  },

  adminPasswordReset: function (email) {
    var encodedURI = window.encodeURI(
      "/forgotPassword/" + email
    );

    return axios.post(encodedURI).then(function (response) {
      return response;
    });
  },
  adminChangePlan: function (sessionId, email, plan) {
    var encodedURI = window.encodeURI(
      "/api/v1/admin/changePlan"
    );
    var body = {
      user: {
        email: email,
        plan: plan
      },
      sessionId: sessionId
    };
    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  adminUpdateUserInfo: function (sessionId, email, name, username, plan, language, timezone) {
    var encodedURI = window.encodeURI(
      "/api/v1/update/user"
    );
    var body = {
      user: {
        email: email,
        username: username,
        name: name,
        plan: plan,
        language: language,
        timezone: timezone
      },
      sessionId: sessionId
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  adminGoogleRequestOk: function (sessionId, email) {
    var encodedURI = window.encodeURI(
      "/api/v1/admin/googleRequestOk"
    );
    var body = {
      user: {
        email: email
      },
      sessionId: sessionId
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  adminTurnIntoAdmin: function (sessionId, email) {
    var encodedURI = window.encodeURI(
      "/api/v1/turnIntoAdmin/user"
    );
    var body = {
      user: {
        email: email
      },
      sessionId: sessionId
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  adminDeleteUser: function (sessionId, email) {
    var encodedURI = window.encodeURI(
      "/api/v1/delete/user"
    );
    var body = {
      user: {
        email: email
      },
      sessionId: sessionId
    };

    return axios.delete(encodedURI, { data: body }).then(function (response) {
      return response;
    });
  },
  adminStreetLightView: function (sessionId, username) {
    var encodedURI = window.encodeURI(
      "/api/v1/activateStreetLightView"
    );
    var body = {
      username: username,
      sessionId: sessionId
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  adminSpatialView: function (sessionId, username) {
    var encodedURI = window.encodeURI(
      "/api/v1/activateSpatialView"
    );
    var body = {
      username: username,
      sessionId: sessionId
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },

  adminReportsView: function (sessionId, username) {
    var encodedURI = window.encodeURI(
      "/api/v1/activateReportsView"
    );
    var body = {
      username: username,
      sessionId: sessionId
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },

  getEvents: function (sessionId, from, to, deviceId, minV, maxV, countOnly = false) {
    var encodedURI = window.encodeURI(
      "/api/v1/events/"
    );
    let params = { from, to, deviceId, countOnly };
    if (minV) params.minV = minV;
    if (maxV) params.maxV = maxV;

    return axios.get(encodedURI, {
      headers: { Authorization: sessionId },
      params
    }).then(function (response) {
      return response;
    });
  },

  sendMassReport: function (period, amount, from, to, sessionId) {
    var encodedURI = window.encodeURI(
      "/api/v1/send_mass_report"
    );
    var body = { period, amount, from, to, sessionId };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },


  blockDevice: function (sessionId, deviceId, duration) {
    var encodedURI = window.encodeURI(
      "/api/v1/admin/blockDevice"
    );
    var body = {
      device: {
        deviceId: deviceId
      },
      sessionId: sessionId,
      duration: duration
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  createDevicein: function (sessionId, name, type, deviceId, groupId) {
    var encodedURI = window.encodeURI(
      "/api/v1/add/device/session"
    );
    var body = {
      deviceinfo: {
        name: name,
        type: type,
        deviceId: deviceId,
        groupId: groupId
      },
      clientSecret: sessionId
    };
    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  deleteDevice: function (sessionId, deviceId, groupId) {
    var encodedURI = window.encodeURI(
      "/api/v1/delete/device/session"
    );
    var body = {
      data: {
        deviceinfo: {
          deviceId: deviceId,
          groupId: groupId
        },
        clientSecret: sessionId
      }
    };

    return axios.delete(encodedURI, body).then(function (response) {
      return response;
    });
  },
  updateDevice: function (sessionId, name, type, deviceId, groupId, initialGroupId) {
    console.log("NEW group:", groupId);

    var encodedURI = window.encodeURI(
      "/api/v1/update/device/" + deviceId + "/session/" + sessionId
    );
    var body = {
      name: name,
      type: type,
      groupId: groupId,
      initialGroupId: initialGroupId
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  updateDeviceUsers: function (sessionId, deviceId, users) {

    var encodedURI = window.encodeURI(
      "/api/v1/update/deviceUsers/"
    );
    var body = {
      device: {
        deviceId: deviceId,
        users: users
      },
      sessionId: sessionId
    };

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  controlDevice: function (sessionId, directive, deviceId) {
    var encodedURI = window.encodeURI(
      "/api/v1/update/device/" + deviceId + "/session/" + sessionId
    );
    var body = directive;

    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },

  createGroup: function (sessionId, name) {
    var encodedURI = window.encodeURI(
      "/api/v1/add/group/" +
      name +
      "/session/" +
      sessionId
    );
    var body = {};
    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  deleteGroup: function (sessionId, groupId) {
    var encodedURI = window.encodeURI(
      "/api/v2/delete/group"
    );
    var body = {
      groupInfo: { groupId: groupId },
      sessionId: sessionId
    };
    return axios.delete(encodedURI, { data: body }).then(function (response) {
      return response;
    });
  },
  updateGroup: function (sessionId, name, groupId) {
    var encodedURI = window.encodeURI(
      "/api/v2/update/group"
    );
    var body = { sessionId: sessionId, groupInfo: { groupId: groupId, name: name } };
    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  controlGroup: function (sessionId, powerState, groupId) {
    var encodedURI = window.encodeURI(
      "/api/v2/control/group"
    );
    var body = {
      sessionId: sessionId,
      groupId: groupId,
      powerState: powerState
    };
    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },

  askVerificationEmail: function (sessionId) {
    var encodedURI = window.encodeURI(
      "/api/v1/emailVerificationResend/" + sessionId
    );
    var body = {
    };
    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },
  updatePassword: function (sessionId, password, newpassword, confirmpassword) {
    var encodedURI = window.encodeURI(
      "/api/v1/passwordUpdate"
    );
    var body = {
      sessionId: sessionId,
      password: password,
      newpassword: newpassword,
      confirmpassword: confirmpassword
    };
    return axios.post(encodedURI, body).then(function (response) {
      return response;
    });
  },


};
