import React, { Component } from 'react';
import "../css/spatial.css";

class Spatial extends Component {


  render() {
    return (
      <iframe title='Spatial' src="https://www.spatial.io/s/Rp2040-micropython-65cd04f50059135629289621?share=3022922460485525679" width="100%" height="100%" frameborder="0"></iframe>
    );
  }
}

export default Spatial;
