import React, { Component } from "react";
import Device from "./Device";
import "../css/group.css";

class Group extends Component {
  state = {
    editing: false,
    visible: true,
    extended: false,
    nameTyped: ""
  };

  okClicked = () => {
    const { onEvent, groupId } = this.props;
    const { nameTyped } = this.state;
    onEvent({
      name: "updateGroup",
      group: { groupId: groupId, name: nameTyped }
    });
    this.setState({ editing: false });
  };
  cancelClicked = () => {
    console.log("Cancel Clicked", this);
    this.setState({ editing: false });
  };
  deleteClicked = () => {
    const { onEvent, groupId, lang } = this.props;
    console.log("Delete Clicked", this);
    if (window.confirm(this.translate("deletegroupconfirm", lang))) {
      onEvent({
        name: "deleteGroup",
        group: { groupId: groupId }
      });
    }
  };
  viewClicked = () => {
    console.log("View Clicked", this);
    const { extended } = this.state;
    if (extended) this.setState({ extended: false });
    else this.setState({ extended: true });
  };
  editClicked = () => {
    this.setState({ editing: true });
  };
  nameChanged = value => {
    this.setState({ nameTyped: value })
  }
  renderEdit() {
    const { editing } = this.state;
    const { lang, groupId } = this.props;
    if (editing && groupId !== "nogroup")
      return (
        <div className="group-edit">
          <input
            className="group-title-edit"
            placeholder={this.translate("enternewname", lang)}
            onChange={event => this.nameChanged(event.target.value)}
          ></input>
          <div className="group-btns-wrapper">
            <button
              onClick={this.okClicked}
              className="btn btn-primary group-btn"
            >
              <ion-icon name="checkmark"></ion-icon> OK
            </button>
            <button
              onClick={this.cancelClicked}
              className="btn btn-primary group-btn"
            >
              <ion-icon name="close"></ion-icon>
              {this.translate("cancel", lang)}
            </button>
            <button
              onClick={this.deleteClicked}
              className="btn btn-primary group-btn"
            >
              <ion-icon name="trash"></ion-icon>
              {this.translate("delete", lang)}
            </button>
          </div>
        </div>
      );
    else return null;
  }
  render() {
    const { lang, devices, groups, name, groupId, onEvent, users, devicesIds, admin, timezone, plan, view } = this.props;
    const { visible } = this.state;

    if (visible && view === "card") {
      return (
        <div className="group-box">
          <div>
            <div className="d-inline-flex flex-row">
              <h5 className="group-title">{name + " "}</h5>
              <div className="inverted group-title">
                <ion-icon name="create" onClick={this.editClicked}></ion-icon>
              </div>
            </div>
            {this.renderEdit()}
          </div>
          <div className="d-inline-flex flex-row justify-content-around flex-wrap group-flex-blox">
            {devices.map(dev => (
              <Device
                view={view}
                admin={admin}
                key={dev._id}
                name={dev.name}
                lang={lang}
                device={dev}
                groups={groups}
                groupName={name}
                groupId={groupId}
                onEvent={onEvent}
                timezone={timezone}
                plan={plan}
              />
            ))}
            <Device
              admin={admin}
              view={view}
              key={"addDevicein" + groupId}
              lang={lang}
              device={null}
              groupName={name}
              groupId={groupId}
              onEvent={onEvent}
              plan={plan}
            ></Device>
          </div>
        </div>
      );
    }
    else if (admin && view === "list") {
      return <div className="admin-card" onClick={this.viewClicked}>
        <div className="row">
          <div className="col">
            <ion-icon name="th"></ion-icon>
            <h6 className="table-field main-label">{name}</h6>
            <h6 className="table-field main-label">{users[0]}</h6>
            <div className="table-field">
              <div className="icon">
                <ion-icon name="outlet"></ion-icon>
                <h6>{devicesIds.length}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    else return null;
  }
  translate(expresion, lang) {
    if (lang !== "EN" && lang !== "ES" && lang !== "DE" && lang !== "CZ" && lang !== "FR" && lang !== "PT" && lang !== "IT" && lang !== "JP") {
      lang = "EN";
    }
    var libr = {
      cancel: { EN: "Cancel", ES: "Cancelar", DE: "Stornieren", CZ: "Zrušit", FR: "Annuler", PT: "Cancelar", IT: "Annulla", JP: "キャンセル", },
      turnon: { EN: "Turn on", ES: "Encender", DE: "Anschalten", CZ: "Zapnout", FR: "Allumer", PT: "Ligar", IT: "Accendere", JP: "オンにする", },
      turnoff: { EN: "Turn off", ES: "Apagar", DE: "Schalte aus", CZ: "Vypnout", FR: "Éteindre", PT: "Desligar", IT: "Spegni", JP: "消す", },
      name: { EN: "Name:", ES: "Nombre:", DE: "Name:", CZ: "Název:", FR: "Nom:", PT: "Nome:", IT: "Nome:", JP: "名前：", },
      delete: { EN: "Delete", ES: "Eliminar", DE: "Löschen", CZ: "Odstranit", FR: "Supprimer", PT: "Excluir", IT: "Elimina", JP: "[削除]", },
      enternewname: { EN: "New name", ES: "Nuevo nombre", DE: "Neuer Name", CZ: "Nové jméno", FR: "Nouveau nom", PT: "Novo nome", IT: "Nuovo nome", JP: "新しい名前", },
      deletegroupconfirm: { EN: "Delete group?", ES: "Eliminar grupo?", DE: "Gruppe löschen?", CZ: "Odstranit skupinu?", FR: "Supprimer le groupe?", PT: "Excluir grupo?", IT: "Elimina gruppo?", JP: "削除グループ？", },
      info: { EN: "Info: ", ES: "info:", DE: "Die Info:", CZ: "Info:", FR: "Info:", PT: "Informação:", IT: "Informazioni:", JP: "インフォメーション：", },
    };
    if (libr[expresion] == null) return "ERROR";
    if (libr[expresion][lang] == null) return "ERROR";
    return libr[expresion][lang];
  }
}

export default Group;
