import React from 'react';
import "./DayLabels.css"

export default function DayLabels() {
  return <div className='day-labels'>
    <div className="day-labels__day"></div>
    <div className="day-labels__day">Lunes</div>
    <div className="day-labels__day">Martes</div>
    <div className="day-labels__day">Miércoles</div>
    <div className="day-labels__day">Jueves</div>
    <div className="day-labels__day">Viernes</div>
    <div className="day-labels__day">Sábado</div>
    <div className="day-labels__day">Domingo</div>
  </div>
};
