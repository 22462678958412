var axios = require("axios");

module.exports = {
	getLicences: function () {
		var encodedURI = window.encodeURI("/api/licences");
		return axios.get(encodedURI).then(function (response) {
			return response;
		});
	},
	updateLicence: function (id, active, client) {
		var encodedURI = window.encodeURI("/api/licences/update");
		var body = { id: id, active: active, client: client };
		return axios.post(encodedURI, body).then(function (response) {
			return response;
		});
	},
	deleteLicence: function (id) {
		var encodedURI = window.encodeURI("/api/licences/" + id);
		return axios.delete(encodedURI).then(function (response) {
			return response;
		});
	}
}