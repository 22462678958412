import React, { useState } from 'react';
import "./Program.css"
import DayProgram from './DayProgram';
import Times from './TimeColumn';
import DayLabels from './DayLabels';
var api = require("../../../../utils/api-v2");

export default function Program({ item, isGroup, isSection, onRefreshInfo }) {
  const [schedule, setSchedule] = useState(item.schedule ? item.schedule : [[], [], [], [], [], [], []]);

  const handleChange = (day, events) => {
    const newSchedule = [...schedule]
    newSchedule[day] = events;
    if (isGroup) {
      api.updateGroup(item._id, { schedule: newSchedule }).then((result) => {
        setSchedule(newSchedule)
        onRefreshInfo();
      })
    } else if (isSection) {
      api.updateSection(item._id, { schedule: newSchedule }).then((result) => {
        setSchedule(newSchedule)
        onRefreshInfo();
      })
    } else {
      api.updateDevice(item._id, { schedule: newSchedule }).then((result) => {
        setSchedule(newSchedule)
        onRefreshInfo();
      })

    }
  }

  return (
    <div className='weekly-schedule'>
      <div className='weekly-schedule__title'>
        <img src="/img/icons/calendar.svg" alt='programm' width={40} height={40} />
        <h3>Programación {item.name}</h3>
      </div>
      <DayLabels />
      <div className='weekly-schedule__day-columns'>
        <Times />
        <DayProgram events={schedule[0]} onChange={(events) => handleChange(0, events)} />
        <DayProgram darker={true} events={schedule[1]} onChange={(events) => handleChange(1, events)} />
        <DayProgram events={schedule[2]} onChange={(events) => handleChange(2, events)} />
        <DayProgram darker={true} events={schedule[3]} onChange={(events) => handleChange(3, events)} />
        <DayProgram events={schedule[4]} onChange={(events) => handleChange(4, events)} />
        <DayProgram darker={true} events={schedule[5]} onChange={(events) => handleChange(5, events)} />
        <DayProgram events={schedule[6]} onChange={(events) => handleChange(6, events)} />
      </div>
    </div>
  );
}