import React, { Component } from 'react';
import Device from "../components/Device";
import User from "../components/User";
import Group from "../components/Group";
import "../css/admin.css";
import ServerTime from '../components/Admin/server_time';
var api = require("../../utils/api");
var refreshInterval;

class Admin extends Component {
    constructor(props) {
        super();
        this.state = {
            admin: props.admin,
            loading: false,
            users: [],
            groups: [],
            devices: [],
            devicesOnline: [],
            alexaEnabled: [],
            googleHomeEnabled: [],
            siriEnabled: [],
            IFTTTEnabled: [],
            usersIndex: 0,
            groupsIndex: 0,
            devicesIndex: 0,
            usersPage: 0,
            groupsPage: 0,
            timeOffset: 0,
            devicesPage: 0,
            searchingText: "",
            itemsLimit: 10,
            refreshInterval: null,
            timezone: props.timezone
        }

    }


    adminEvent = event => {
        const { sessionId, admin, setCookie } = this.props;

        if (admin === "Admin") console.log("mynest Event", event);
        if (event.name === "getAdminInfo") {
            this.setState({ loading: true });

            if (sessionId !== "") {
                api.getAdminInfo(sessionId).then(
                    function (data) {
                        if (admin === "Admin") console.log(data.data);
                        if (data.data.result !== "success") {
                            setCookie("");
                            return null;
                        }
                        var usersFiltered = []
                        var devicesFiltered = []
                        var groupsFiltered = []
                        if (event.type === "first") {

                            for (let i = 0; i < 10; i++) {
                                const user = data.data.users[i];
                                if (user != null) usersFiltered.push(user);
                            }
                            for (let i = 0; i < 10; i++) {
                                const device = data.data.devices[i];
                                if (device != null) devicesFiltered.push(device);
                            }
                            for (let i = 0; i < 10; i++) {
                                const group = data.data.groups[i];
                                if (group != null) groupsFiltered.push(group);
                            }
                            this.setState({
                                usersFiltered: usersFiltered,
                                devicesFiltered: devicesFiltered,
                                groupsFiltered: groupsFiltered
                            });
                        }
                        for (let i = 0; i < data.data.users.length; i++) {
                            const user = data.data.users[i];
                            data.data.users[i].devices = 0;
                            data.data.users[i].groups = 0;
                            for (let j = 0; j < data.data.devices.length; j++) {
                                const device = data.data.devices[j];
                                if (device.users.indexOf(user.username) > -1) data.data.users[i].devices++;
                            }
                            for (let j = 0; j < data.data.groups.length; j++) {
                                const group = data.data.groups[j];
                                if (group.users.indexOf(user.username) > -1) data.data.users[i].groups++;
                            }
                        }

                        this.setState({
                            users: data.data.users,
                            devices: data.data.devices,
                            devicesOnline: data.data.devicesOnline,
                            groups: data.data.groups,
                            alexaEnabled: data.data.alexaEnabled,
                            googleHomeEnabled: data.data.googleHomeEnabled,
                            siriEnabled: data.data.siriEnabled,
                            IFTTTEnabled: data.data.IFTTTEnabled,
                            timeOffset: data.data.timeOffset,
                            loading: false
                        });
                    }.bind(this)
                );
            } else {
                window.location = "https://www.smartnest.cz/login?lang=EN";
            }
        } else if (event.name === "resetPassword") {
            api.adminPasswordReset(event.user.email).then(
                function (data) {
                    window.alert(JSON.stringify(data.data))

                }
            );
        } else if (event.name === "updateInfo") {
            api.adminUpdateUserInfo(sessionId, event.user.email,
                event.user.name,
                event.user.username,
                event.user.plan,
                event.user.language,
                event.user.timezone).then(
                    function (data) {
                        window.alert(JSON.stringify(data.data))
                    }
                );
        } else if (event.name === "googleRequestOk") {
            api.adminGoogleRequestOk(sessionId, event.user.email).then(
                function (data) {
                    window.alert(JSON.stringify(data.data))
                }
            );
        } else if (event.name === "deleteUser") {
            if (window.confirm("Delete User?"))
                api.adminDeleteUser(sessionId, event.user.email).then(
                    function (data) {
                        if (window.confirm("Result: " + data.data.result)) {
                            this.adminEvent({ name: "getAdminInfo" });
                        } else {
                            this.adminEvent({ name: "getAdminInfo" });
                        }
                    }.bind(this)

                );
        } else if (event.name === "turnIntoAdmin") {
            if (window.confirm("Seguro que desea dar a este usuario permisos de administrador?"))
                api.adminTurnIntoAdmin(sessionId, event.user.email).then(
                    function (data) {
                        if (window.confirm("Result: " + data.data.result)) {
                            this.adminEvent({ name: "getAdminInfo" });
                        } else {
                            this.adminEvent({ name: "getAdminInfo" });
                        }
                    }.bind(this)

                );
        } else if (event.name === "streetLightView") {
            if (window.confirm("Activar vista de Luminarias para este usuario?"))
                api.adminStreetLightView(sessionId, event.username).then(
                    function (data) {
                        if (window.confirm("Result: " + data.data.result)) {
                            this.adminEvent({ name: "getAdminInfo" });
                        } else {
                            this.adminEvent({ name: "getAdminInfo" });
                        }
                    }.bind(this)

                );
        } else if (event.name === "spatialView") {
            if (window.confirm("Activar vista de Spatial para este usuario?"))
                api.adminSpatialView(sessionId, event.username).then(
                    function (data) {
                        if (window.confirm("Result: " + data.data.result)) {
                            this.adminEvent({ name: "getAdminInfo" });
                        } else {
                            this.adminEvent({ name: "getAdminInfo" });
                        }
                    }.bind(this)

                );
        } else if (event.name === "reportsView") {
            if (window.confirm("Activar vista de Reportes para este usuario?"))
                api.adminReportsView(sessionId, event.username).then(
                    function (data) {
                        if (window.confirm("Result: " + data.data.result)) {
                            this.adminEvent({ name: "getAdminInfo" });
                        } else {
                            this.adminEvent({ name: "getAdminInfo" });
                        }
                    }.bind(this)

                );
        } else if (event.name === "blockDevice") {
            if (window.confirm("Block Device?"))
                api.blockDevice(sessionId, event.device.deviceId, event.duration).then(
                    function (data) {
                        window.alert(JSON.stringify(data.data))
                    }
                );
        } else if (event.name === "updateDeviceUsers") {
            api.updateDeviceUsers(
                sessionId,
                event.device.deviceId,
                event.device.users
            )
                .then(
                    function (data) {
                        window.alert(JSON.stringify(data.data))
                    }
                );
        } else if (event.name === "deleteDevice") {
            if (window.confirm("Delete Device?"))
                api.deleteDevice(sessionId, event.device.deviceId, event.device.groupId)
                    .then(
                        function (data) {
                            window.alert(JSON.stringify(data.data))
                        }
                    );
        } else if (event.name === "deleteGroup") {
            if (window.confirm("Delete Group?"))
                api.deleteGroup(
                    sessionId,
                    event.group.groupId
                )
                    .then(
                        function (data) {
                            window.alert(JSON.stringify(data.data))
                        }
                    );
        }
    }

    componentDidMount() {
        //const{ admin } = this.props;
        this.adminEvent({ name: "getAdminInfo", type: "first" });
        //refreshInterval =  setInterval(this.adminEvent,3000,{ name: "getAdminInfo"})
    }

    componentWillUnmount() {
        clearInterval(refreshInterval);
    }

    searchChanged = target => {
        this.setState({ searchingText: target.value.toLowerCase() });
    }

    changePageClicked = function (target) {
        const { itemsLimit } = this.state
        const { itemsN } = target


        if (target.devicesPage != null && target.devicesPage >= 0 && target.devicesPage < Math.ceil(itemsN / itemsLimit))
            this.setState({ devicesPage: target.devicesPage, devicesIndex: target.devicesPage * itemsLimit })

        else if (target.usersPage != null && target.usersPage >= 0 && target.usersPage < Math.ceil(itemsN / itemsLimit))
            this.setState({ usersPage: target.usersPage, usersIndex: target.usersPage * itemsLimit })

        else if (target.groupsPage != null && target.groupsPage >= 0 && target.groupsPage < Math.ceil(itemsN / itemsLimit))
            this.setState({ groupsPage: target.groupsPage, groupsIndex: target.usersPage * itemsLimit })
    }

    render() {
        const { lang, admin } = this.props;
        const {
            loading,
            devices,
            users,
            groups,
            devicesOnline,
            searchingText,
            usersPage,
            devicesPage,
            groupsPage,
            devicesIndex,
            usersIndex,
            itemsLimit,
            timezone,
            timeOffset,
            groupsIndex } = this.state;

        let usersFiltered = users.filter(user => {
            if (user.username === "DeviceManager") return null;
            if ((user.username.toLowerCase().search(searchingText) >= 0) ||
                (user.email.toLowerCase().search(searchingText) >= 0))
                return true;
            else return false;
        })

        let devicesFiltered = devices.filter(device => {
            if ((device.name.toLowerCase().search(searchingText) >= 0) ||
                (device._id.toString().toLowerCase().search(searchingText) >= 0) ||
                (device.users.toString().toLowerCase().search(searchingText) >= 0) ||
                (device.connected && searchingText === "online") ||
                (searchingText === "no users" && device.users.length === 0) ||
                (device.manufacturer.search(searchingText) >= 0) ||
                (device.type.toLowerCase().search(searchingText) >= 0)
            ) return true;
            else return false;
        })

        let groupsFiltered = groups.filter(group => {
            if ((group.name.toLowerCase().search(searchingText) >= 0) ||
                (searchingText === "no users" && group.users.length === 0) ||
                (group.users.toString().toLowerCase().search(searchingText) >= 0))
                return true;

            else return false;
        })

        return (<div id="main" className="admin-wrapper">
            <div className="content">
                <div id="Dashboard">
                    <h3>{this.translate("insights", lang)}</h3>
                    <div className="d-flex flex-grow-1 justify-content-center flex-wrap">
                        <div className="dashboard-card animated bounceInDown">
                            <div className="d-flex"><i className="fa fa-user"></i>
                                <h5 className="text-center insight-title">{this.translate("users", lang)}</h5>
                            </div>
                            <div className="d-flex">
                                <h6>Total:</h6>
                                <h6 className="insight-value">{users.length}</h6>
                            </div>
                        </div>
                        <div className="dashboard-card animated bounceInDown">
                            <div className="d-flex"><i className="fa fa-microchip"></i>
                                <h5 className="text-center insight-title">{this.translate("devices", lang)}</h5>
                            </div>
                            <div className="d-flex">
                                <h6>Total:</h6>
                                <h6 className="insight-value">{devices.length}</h6>
                            </div>
                            <div className="d-flex">
                                <h6>Online:</h6>
                                <h6 className="insight-value">{devicesOnline.length}</h6>
                            </div>
                        </div>

                        <ServerTime offset={timeOffset} loading={loading} onChange={(offset) => {
                            api.setServerTimeOffset(offset).then(() => this.adminEvent({ name: "getAdminInfo" }));
                        }}></ServerTime>

                    </div>
                </div>
                <div className="text-right d-inline-flex spaced-div">
                    <i className="fa fa-search"></i>
                    <h6 className="search-lbl">{this.translate("search", lang)}</h6>
                    <input type="search" onChange={event => this.searchChanged(event.target)} />
                </div>
                <div className="spaced-div">
                    <h3>{this.translate("users", lang)}</h3>
                    {usersFiltered.map((usr, idx) => {
                        if (idx >= usersIndex && idx < usersIndex + itemsLimit)
                            return (<User
                                key={usr._id}
                                name={usr.name}
                                lang={lang}
                                user={usr}
                                groups={usr.groups}
                                admin={admin}
                                devices={usr.devices}
                                onEvent={this.adminEvent}
                            />)
                        else return null;
                    })}

                    <div className="text-center d-flex justify-content-center">
                        <div onClick={() => this.changePageClicked({ usersPage: usersPage - 1, intemsN: usersFiltered.length })}><ion-icon name="arrow-dropleft"></ion-icon></div>
                        <h6 className="page-label"> {this.translate("page", lang)} {usersPage + 1} ({usersFiltered.length} {this.translate("results", lang)})</h6>
                        <div onClick={() => this.changePageClicked({ usersPage: usersPage + 1, intemsN: usersFiltered.length })}><ion-icon name="arrow-dropright"></ion-icon></div>
                    </div>
                </div>
                <div className="spaced-div">
                    <h3>{this.translate("devices", lang)}</h3>
                    {devicesFiltered.map((dev, idx) => {

                        if (idx >= devicesIndex && idx < devicesIndex + itemsLimit)
                            return (
                                <Device
                                    view="list"
                                    key={dev._id}
                                    name={dev.name}
                                    lang={lang}
                                    device={dev}
                                    groups={[]}
                                    groupName={"name"}
                                    groupId={"id"}
                                    onEvent={this.adminEvent}
                                    admin={true}
                                    timezone={timezone}
                                />)
                        else return null;
                    }
                    )}


                    <div className="text-center d-flex justify-content-center">
                        <div onClick={() => this.changePageClicked({ devicesPage: devicesPage - 1, itemsN: devicesFiltered.length })}><ion-icon name="arrow-dropleft"></ion-icon></div>
                        <h6 className="page-label">{this.translate("page", lang)} {devicesPage + 1} ({devicesFiltered.length} {this.translate("results", lang)})</h6>
                        <div onClick={() => this.changePageClicked({ devicesPage: devicesPage + 1, itemsN: devicesFiltered.length })}><ion-icon name="arrow-dropright"></ion-icon></div>
                    </div>
                </div>
                <div className="spaced-div">
                    <h3>{this.translate("groups", lang)}</h3>
                    {groupsFiltered.map((gr, idx) => {
                        if (idx >= groupsIndex && idx < groupsIndex + itemsLimit)
                            return (
                                <Group
                                    view="list"
                                    key={gr._id}
                                    name={gr.name}
                                    lang={lang}
                                    grice={gr}
                                    groups={[]}
                                    groupId={gr._id}
                                    onEvent={this.adminEvent}
                                    admin={true}
                                    users={gr.users}
                                    devicesIds={gr.devices}
                                />)
                        else return null;
                    })}
                    <div className="text-center d-flex justify-content-center">
                        <div onClick={() => this.changePageClicked({ groupsPage: groupsPage - 1, intemsN: groupsFiltered.length })}><ion-icon name="arrow-dropleft"></ion-icon></div>
                        <h6 className="page-label"> {this.translate("page", lang)} {groupsPage + 1} ({groupsFiltered.length} {this.translate("results", lang)})</h6>
                        <div onClick={() => this.changePageClicked({ groupsPage: groupsPage + 1, intemsN: groupsFiltered.length })}><ion-icon name="arrow-dropright"></ion-icon></div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    translate(expresion, lang) {
        if (lang !== "EN" && lang !== "ES" && lang !== "DE" && lang !== "CZ" && lang !== "FR") {
            lang = "EN";
        }
        var libr = {
            insights: { EN: "Insights", ES: "Dashboard", DE: "Insights", CZ: "Insights", FR: "Insights", PT: "Intuições", IT: "Approfondimenti", },
            users: { EN: "Users", ES: "Usuarios", DE: "Benutzer", CZ: "uživatelé", FR: "Utilisateurs", PT: "Comercial", IT: "utenti", },
            devices: { EN: "devices", ES: "Dispositivos", DE: "Geräte", CZ: "zařízení", FR: "dispositifs", PT: "dispositivos", IT: "dispositivi", },
            groups: { EN: "groups", ES: "Grupos", DE: "Gruppen", CZ: "skupiny", FR: "groupes", PT: "grupos", IT: "gruppi", },
            integrations: { EN: "Integrations", ES: "Integraciones", DE: "Integrationen", CZ: "integrace", FR: "intégrations", PT: "integrações", IT: "integrazioni", },
            created: { EN: "Created", ES: "Creado", DE: "Erstellt", CZ: "Vytvořeno", FR: "Créé", PT: "Criada", IT: "Creato", },
            blocked: { EN: "Blocked", ES: "Bloqueado", DE: "verstopft", CZ: "Blokováno", FR: "Bloqué", PT: "Bloqueado", IT: "bloccato", },
            lastconnection: { EN: "Last connection", ES: "Última conexión", DE: "Letzter", CZ: "Poslední připojení", FR: "dernière connexion", PT: "Última conexão", IT: "Ultima connessione", },
            until: { EN: "Until", ES: "Hasta", DE: "Bis um", CZ: "Dokud", FR: "Jusqu'à", PT: "Até", IT: "Fino a", },
            yes: { EN: "Yes", ES: "SI", DE: "Ja", CZ: "Ano", FR: "Oui", PT: "sim", IT: "sì", },
            no: { EN: "No", ES: "NO", DE: "Nein", CZ: "Ne", FR: "Non", PT: "Não", IT: "No", },
            block: { EN: "Block", ES: "Bloquear", DE: "Block", CZ: "Blok", FR: "Bloquer", PT: "Quadra", IT: "Bloccare", },
            manufacturer: { EN: "Manufacturer", ES: "Fabricante", DE: "Hersteller", CZ: "Výrobce", FR: "Fabricant", PT: "Fabricante", IT: "fabbricante", },
            updateusers: { EN: "Update users", ES: "Actualizar los usuarios", DE: "Update Benutzer", CZ: "Aktualizovat uživatele", FR: "Les utilisateurs de mise à jour", PT: "atualizar usuários", IT: "gli utenti di aggiornamento", },
            deviceinfo: { EN: "Device Info", ES: "Información del dispositivo", DE: "Geräteinformationen", CZ: "Informace o zařízení", FR: "Info appareil", PT: "Informação do dispositivo", IT: "Informazioni sul dispositivo", },
            unblock: { EN: "Unblock", ES: "Desbloquear", DE: "Entblocken", CZ: "Odblokovat", FR: "Débloquer", PT: "Desbloquear", IT: "Sbloccare", },
            search: { EN: "Search", ES: "Buscar", DE: "Suche", CZ: "Vyhledávání", FR: "Chercher", PT: "Procurar", IT: "Ricerca", },
            results: { EN: "Results", ES: "resultados", DE: "Ergebnisse", CZ: "Výsledek", FR: "Résultats", PT: "Resultados", IT: "risultati", },
            page: { EN: "Page", ES: "Página", DE: "Seite", CZ: "Stránka", FR: "Page", PT: "Página", IT: "Pagina", },
        };
        if (libr[expresion] == null) return "ERROR";
        if (libr[expresion][lang] == null) return "ERROR";
        return libr[expresion][lang];
    }
}

export default Admin;