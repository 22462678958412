import React, { useState } from 'react';
import "./EventDetails.css"

export default function EventDetails({ event, onChange }) {

  const [hours, setHours] = useState(event.hours)
  const [minutes, setMinutes] = useState(event.minutes)
  const [duration, setDuration] = useState(event.duration)

  const handleTimeChange = (value) => {
    setHours(value.hours);
    setMinutes(value.minutes);
  }
  const handleDurationChange = (value) => {
    setDuration(value);
  }
  const handleSaveclicked = () => {
    const newEvent = { ...event }
    newEvent.hours = hours;
    newEvent.minutes = minutes;
    newEvent.duration = duration;
    onChange(newEvent);
  }

  console.log(event);

  return <div className='event-details-box__background'>
    <div className='event-details-box'>
      <div className='event-details-box__title'>
        <img src="/img/icons/calendar-edit.svg" alt='programm' width={40} height={40} />
        <h5>Editar evento</h5>
      </div>
      <div className='event-details-box__item'>
        <DetailLabel imageName="play.svg" >Inicio</DetailLabel>
        <TimeInput value={{ hours: hours, minutes: minutes }} onChange={handleTimeChange} />

      </div>

      <div className='event-details-box__item'>
        <DetailLabel imageName="timer.svg" >Duración</DetailLabel>
        <DurationInput value={duration} onChange={handleDurationChange} />

      </div>

      <button className='btn btn-primary' onClick={handleSaveclicked}>
        Guardar
      </button>

    </div>
  </div>
};


function DetailLabel({ imageName, children }) {
  return <div className='event-details-box__item__label'>
    <img src={`/img/icons/${imageName}`} alt='programm' width={20} height={20} />
    <h6>{children}</h6>
  </div>
}

function TimeInput({ value, onChange }) {

  const { hours, minutes } = value;
  const handleChange = (hours, minutes) => {
    onChange({ hours: Number(hours), minutes: Number(minutes) })
  }

  return <div className='event-details-box__item__time-input'>
    <input value={`${hours}`} onChange={(e) => handleChange(e.target.value, minutes)} />
    <h3>:</h3>
    <input value={`${minutes}`} onChange={(e) => handleChange(hours, e.target.value)} />
  </div>
}

function DurationInput({ value, onChange }) {

  return <div className='event-details-box__item__duration-input'>
    <input value={`${value}`} onChange={(e) => onChange(Number(e.target.value))} />
    <h6>minutos</h6>
  </div>
}