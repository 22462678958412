import React, { useCallback } from 'react';
import "../css/reports.css";
var api = require("../../utils/api");

export default function MassReportsPage(props) {
  const { sessionId } = props;
  const [from, setFrom] = React.useState();
  const [to, setTo] = React.useState();

  const sendReport = useCallback(async (period, amount, from, to) => {
    if (!amount && !from && !to) {
      window.alert("Por favor seleccione un periodo o un rango de fechas");
      return;
    }

    await api.sendMassReport(period, amount, from, to, sessionId).then(function (response) {
      window.alert("La creación del reporte ha iniciado, en unos minutos recibirá un correo con el archivo adjunto");
    });

  }, [sessionId])


  return (
    <div className='d-flex align-items-center flex-column' style={{ marginTop: "75px", height: "100%", backgroundColor: "#595959", color: "#ffffff" }}>
      <h2 style={{ color: "#8bbb59", marginTop: "20px" }}>Reportes Masivos</h2>
      <div className='d-flex align-items-center'>
        <div>
          <div className='report-button' onClick={() => sendReport("week", 1)}>1 Semana</div>
          <div className='report-button' onClick={() => sendReport("week", 3)}>3 Semanas</div>
          <div className='report-button' onClick={() => sendReport("week", 4)}>6 Semanas</div>
        </div>
        <div>
          <div className='report-button' onClick={() => sendReport("month", 1)}>1 Mes</div>
          <div className='report-button' onClick={() => sendReport("month", 3)}>3 Meses</div>
          <div className='report-button' onClick={() => sendReport("month", 9)}>9 Meses</div>
        </div>
      </div>

      <div className='d-flex align-items-end' style={{ marginTop: "50px" }}>
        <div className='d-flex align-items-start flex-column'>
          <h6>Filtro personalizado</h6>
          <div>Inicio:</div>
          <input type="date" onChange={(event) => { setFrom(event.target.value); }} />
          <div>Fin:</div>
          <input type="date" onChange={(event) => { setTo(event.target.value); }} />
        </div>
        <div className='report-button d-flex align-items-center justify-content-center' style={{ marginTop: "20px" }} onClick={() => sendReport("custom", null, from, to)}>
          <img src="/img/icons/send-email.svg" alt="send" height={30} width={50} />
          <div>Enviar</div>
        </div>
      </div>

    </div>
  );
}

