import React from 'react';
import "./TimeColumn.css"
export default function TimeColumn() {
  return <div className="time-column">
    <div className='time-cell'>0:00</div>
    <div className='time-cell'>2:00</div>
    <div className='time-cell'>4:00</div>
    <div className='time-cell'>6:00</div>
    <div className='time-cell'>8:00</div>
    <div className='time-cell'>10:00</div>
    <div className='time-cell'>12:00</div>
    <div className='time-cell'>14:00</div>
    <div className='time-cell'>16:00</div>
    <div className='time-cell'>18:00</div>
    <div className='time-cell'>20:00</div>
    <div className='time-cell'>22:00</div>
  </div>
};
