import React, { Component } from 'react';
import '../../css/lampDetails.css'
import Program from '../../components/streetLight/Program/Program';
var api = require("../../../utils/api-v2");

class GroupDetails extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: false, name: props.group.name, time: "00:00", value: "ON" }
	}
	render() {
		const { editing } = this.state

		return (
			<div className='long-detail-comtainer'>
				{editing ? this.renderEdit() : this.renderNormal()}
			</div>
		);
	}

	renderNormal() {
		const { group, onRefreshInfo } = this.props

		return (
			<div>
				<Program item={group} isSection={false} isGroup={true} onRefreshInfo={onRefreshInfo} />

				<div className="d-flex justify-content-around my-3">
					<button className='btn btn-secondary' onClick={() => { this.setState({ editing: true }) }}>
						Editar Grupo
					</button>
				</div>

			</div>
		)
	}

	renderEdit() {
		const { group, onClose } = this.props
		const { name } = this.state

		let doneClicked = () => {
			api.updateGroup(group._id, { name: name })
			this.setState({ editing: false });
		}
		let deleteClicked = () => {
			if (group.devices.length > 0) {
				window.alert("El grupo debe estar vacío para poder ser eliminado")

			} else if (window.confirm("Eliminar Grupo " + name + "?")) {
				api.deleteGroup(group._id);
				onClose();
			}
		}

		return (
			<div className='detail-edit-form'>
				<div className="d-flex justify-content-between my-2">
					<div >Nombre</div>
					<div ><input type="text" onChange={(e) => { this.setState({ name: e.target.value }) }} value={name} /></div>
				</div>

				<div className="detail-edit-buttons">
					<button className="btn btn-secondary" onClick={doneClicked}>OK</button>
					<button className="btn btn-danger" onClick={deleteClicked}>Eliminar</button>
				</div>


			</div>

		)
	}
}

export default GroupDetails;