import React, { useCallback, useEffect, useState } from 'react';
var api = require("../../../utils/api.js");

export default function SorterBandTable(props) {
  const { sorterId, bandId, sessionId } = props;
  const [personCount, setPersonCount] = useState(0);
  const [shiftId, setShiftId] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const workedHours = 6.5;
  const targetCount = 410;

  const [sorterDevice, setSorterDevice] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [bandDevice, setBandDevice] = React.useState({});
  const [sorterEventCount, setSorterEventCount] = React.useState(0);
  const [bandEvents, setBandEvents] = React.useState([]);

  const getData = useCallback(async () => {
    setLoading(true);
    let now = new Date();
    let hour = now.getHours();
    let shift = (hour >= 7 && hour < 15) ? "AM" : (hour >= 15 && hour < 23) ? "PM" : "Nocturno";
    let shiftStartHour = (shift === "AM") ? 7 : (shift === "PM") ? 15 : 23;
    let shiftStartDayOffset = (shift === "Nocturno" && hour !== 23) ? 1 : 0;
    let shiftStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - shiftStartDayOffset, shiftStartHour, 0, 0);
    await api.getEvents(sessionId, shiftStart.getTime(), now.getTime(), sorterId, undefined, undefined, true).then(function (response) {
      if (response.data) {
        setSorterEventCount(response.data.count);
        let device = response.data.device;
        setSorterDevice(device);
        if (device.personCount != null) { setPersonCount(device.personCount); }
        if (device.shiftId != null) { setShiftId(device.shiftId); }
      }
    });
    await api.getEvents(sessionId, shiftStart.getTime(), now.getTime(), bandId, 0, 25200).then(function (response) {
      if (response.data) {
        setBandEvents(response.data.events);
        setBandDevice(response.data.device);
        setLastUpdated(now);
      }
    });
    setLoading(false);

  }, [bandId, sessionId, sorterId])

  const savePersonCount = useCallback(async (count) => {
    api.controlDevice(sessionId, { personCount: count }, sorterId).then(function (response) {
      if (response.data) {
        setPersonCount(count);
      }
    });
  }, [sessionId, sorterId]);

  const saveShiftId = useCallback(async (id) => {
    api.controlDevice(sessionId, { shiftId: id }, sorterId).then(function (response) {
      if (response.data) {
        setShiftId(id);
      }
    });
  }, [sessionId, sorterId]);

  useEffect(() => {
    getData();
    const interval = setInterval(getData, 300000);
    return () => clearInterval(interval);
  }, [getData]);

  const personCountDropDown = () => {
    return <div className="dropdown">
      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {personCount}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <div className="dropdown-item" onClick={() => savePersonCount(0)}>0</div>
        <div className="dropdown-item" onClick={() => savePersonCount(2)}>2</div>
        <div className="dropdown-item" onClick={() => savePersonCount(3)}>3</div>
        <div className="dropdown-item" onClick={() => savePersonCount(4)}>4</div>
        <div className="dropdown-item" onClick={() => savePersonCount(5)}>5</div>
        <div className="dropdown-item" onClick={() => savePersonCount(6)}>6</div>
      </div>
    </div>
  }

  const shiftIdDropDown = () => {
    return <div className="dropdown">
      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {shiftId}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <div className="dropdown-item" onClick={() => saveShiftId(0)}>0</div>
        <div className="dropdown-item" onClick={() => saveShiftId(1)}>1</div>
        <div className="dropdown-item" onClick={() => saveShiftId(2)}>2</div>
      </div>
    </div>
  }

  const productivity = sorterEventCount / (personCount * workedHours);
  const achievedPercentage = productivity * 100 / targetCount;
  const achievedColor = achievedPercentage < 100 ? "red" : "green";

  let startEvents = bandEvents.filter(event => event.event === "start");
  let timeIdle = (startEvents.reduce((acc, event) => acc + (event.v ?? 0), 0)) / 60;
  const directHours = (workedHours * 60 - timeIdle) / 60;
  const directProductivity = sorterEventCount / (directHours * personCount);

  return <div className='m-5'>
    <table className="table table-bordered">
      <thead>
        <tr>
          <th scope="col"> {sorterDevice?.name}-{bandDevice?.name} </th>
          <th scope="col">  {loading && <img className='loading-animate' src="img/loading.png" alt="loading" />}  </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Cuenta</td>
          <td>{sorterEventCount}</td>
        </tr>
        <tr>
          <td>Turno</td>
          <td>{shiftIdDropDown()}</td>
        </tr>
        <tr>
          <td>Dotación</td>
          <td>{personCountDropDown()}</td>
        </tr>
        <tr>
          <td>Target ingresado</td>
          <td colSpan="2">{targetCount}</td>
        </tr>
        <tr>
          <td>Pro. Obtenida</td>
          <td colSpan="2" >{Number.isNaN(productivity) ? "--" : productivity.toFixed(0)}</td>
        </tr>
        <tr>
          <td>Cumplimiento</td>
          <td colSpan="2" style={{ color: achievedColor }}>{Number.isNaN(achievedPercentage) ? "--" : achievedPercentage.toFixed(0)}%</td>
        </tr>
        <tr>
          <th scope="row">Horas directas</th>
          <td colSpan="2">{directHours.toFixed(1)}</td>
        </tr>
        <tr>
          <th scope="row">Prod. directa</th>
          <td colSpan="2">{Number.isNaN(directProductivity) ? "--" : directProductivity.toFixed(0)}</td>
        </tr>
      </tbody>
    </table>
    <p style={{ color: "grey", fontSize: 12 }} >Actualizado: {lastUpdated.toLocaleTimeString()}</p>
  </div>


}

