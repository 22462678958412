
import React from 'react';
import SorterShiftChart from '../components/Reports/SorterShiftChart';

export default function ProductivityPage(props) {
  const [time, setTime] = React.useState(new Date());
  const [shift, setShift] = React.useState("AM");
  const { sessionId } = props;

  React.useEffect(() => {
    const interval = setInterval(() => {
      let now = new Date();
      setTime(now);
      let hour = now.getHours();
      let shift = (hour >= 7 && hour < 15) ? "AM" : (hour >= 15 && hour < 23) ? "PM" : "Nocturno";
      setShift(shift)

    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='d-flex align-items-center justify-text-center flex-column' style={{ marginTop: "80px", height: "100%" }}>
      <h2>{time.toLocaleDateString()} {time.toLocaleTimeString()}</h2>
      <h2>Horario: {shift}</h2>
      <div className='d-flex align-items-center justify-content-center flex-wrap' style={{ height: "100%" }}>
        <SorterShiftChart sorterId="65e21d7d12528c030db80ddd" sessionId={sessionId} />
        <SorterShiftChart sorterId="66216ac5fbc3ed555728789f" sessionId={sessionId} />
        <SorterShiftChart sorterId="6623f0dc0a0e21087de9ff9b" sessionId={sessionId} />
        <SorterShiftChart sorterId="6623f2070a0e21087de9ff9c" sessionId={sessionId} />
      </div>
    </div>
  );
}

