import React, { useEffect } from "react";

var api = require("../../../../utils/api.js");


export default function ConveyorBand(props) {
  const { device } = props;
  const [events, setEvents] = React.useState([]);
  const [shift, setShift] = React.useState("AM");

  useEffect(() => {
    let now = new Date();
    let hour = now.getHours();
    let shift = (hour >= 7 && hour < 15) ? "AM" : (hour >= 15 && hour < 23) ? "PM" : "Nocturno";
    setShift(shift)
    let shiftStartHour = (shift === "AM") ? 7 : (shift === "PM") ? 15 : 23;
    let shiftStartDayOffset = (shift === "Nocturno" && hour !== 23) ? 1 : 0;
    let shiftStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - shiftStartDayOffset, shiftStartHour, 0, 0);
    console.log({ shiftStart });
    api.getEvents("token", shiftStart.getTime(), now.getTime(), device._id).then(function (response) {
      if (response.data) { setEvents(response.data.events); }
    });
  }, [device._id, device.deviceId]);

  let src = "img/device-features/conveyor.png"
  let sensor1 = events.filter(event => event.event === "sensor1");
  let sensor2 = events.filter(event => event.event === "sensor2");
  let sensor3 = events.filter(event => event.event === "sensor3");
  let shiftTotal = sensor1.length + sensor2.length + sensor3.length;

  return (
    <div style={{ marginBottom: "10px" }}>
      <div className="row device-feature">
        <div className="col-4">
          <img src={src} alt="toogle on" width={50} height={50} />
        </div>
        <div className="col-8">
          <h3 className="feature-value-title">Total: {shiftTotal}</h3>
          <h3 className="feature-value-title">Turno {shift}</h3>
        </div>
      </div>
      <div>
        <h5 className="feature-value-lbl" >Parciales</h5>
        <div className="col">
          <h5 className="feature-value-lbl">Banda 1: {sensor1.length}</h5>
        </div>
      </div>
    </div >

  )

};
