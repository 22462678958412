import React, { Component } from 'react';
var api = require("../../utils/api-licences");

var refreshInterval;



class Licences extends Component {
	state = { licences: [], licencesSt: {} }

	componentDidMount() {
		this.licenceEvent({ name: "getInfo" });
		refreshInterval = setInterval(this.licenceEvent, 3000, { name: "getInfo" });
	}

	componentWillUnmount() {
		clearInterval(refreshInterval);
	}

	licenceEvent = event => {
		const { setCookie } = this.props

		if (event.name === "getInfo") {

			api.getLicences().then(
				function (data) {

					if (data.data.error === "Logged out") {
						clearInterval(refreshInterval);
						setCookie("");
						return;
					}

					this.setState({
						licences: data.data.licences,
						loading: false
					});

				}.bind(this)
			);
		} else if (event.name === "deleteLicence") {

			api.deleteLicence(event.id).then(
				this.licenceEvent({ name: "getInfo" })
			);
		}
	}
	render() {
		return (<div className="admin-wrapper">
			{this.renderHead()}
			{this.renderTable()}
		</div>);
	}

	renderHead() {
		return <div className="my-5">
			<img src="/img/icons/licence.png" alt="licencia" />
			<h3>Licencias</h3>
		</div>

	}
	renderTable() {
		const { licences, licencesSt } = this.state;

		return (<div className="table-responsive">
			<table className="table table-hover">
				<thead>
					<tr>
						<th scope="col">Estado</th>
						<th scope="col">Cliente</th>
						<th scope="col">Alias</th>
						<th scope="col">Disco</th>
						<th scope="col">Cpu</th>
						<th scope="col">Tipo</th>
						<th scope="col">IP</th>
						<th scope="col">Creada</th>
						<th scope="col">Último uso</th>
						<th scope="col">Activación</th>
						<th scope="col">Eliminar</th>
					</tr>
				</thead>
				<tbody>
					{licences.map((licence) => (
						<tr key={licence._id}>
							<td><img src={licence.lastAllowed ? "/img/icons/licence-active.svg" : "/img/icons/licence-inactive.svg"} alt="licence" width="30" height="30" /></td>
							<td><input type="text" value={licencesSt[licence._id] ? licencesSt[licence._id] : ""} placeholder={licence.client} onChange={(e) => {
								var change = {};
								change[licence._id] = e.target.value
								this.setState({ licencesSt: change });
							}} onKeyPress={async (e) => {
								if (e.key === 'Enter') {
									await api.updateLicence(licence._id, licence.active, licencesSt[licence._id]);
									window.alert("Licencia actualizada.");
									this.setState({ licencesSt: {} });

								}

							}} /></td>
							<td>{licence.al}</td>
							<td>{licence.nhd}</td>
							<td>{licence.ncpu}</td>
							<td>{licence.tp}</td>
							<td>{licence.ip}</td>
							<td>{new Date(licence.created).toLocaleDateString("es-CO")}</td>
							<td>
								<div>
									{new Date(licence.lastUse).toLocaleDateString("es-CO")}
								</div>
								<div>
									{new Date(licence.lastUse).toLocaleTimeString("es-CO")}
								</div>
							</td>
							<td><input type="checkbox" checked={licence.active} onChange={async () => {
								await api.updateLicence(licence._id, !licence.active);
								this.licenceEvent({ name: "getInfo" });

							}} /></td>
							<td><ion-icon name="trash" onClick={() => {
								if (window.confirm("Eliminar esta licencia?")) {
									this.licenceEvent({ name: "deleteLicence", id: licence._id });
								}

							}} /></td>
						</tr>
					))}

				</tbody>
			</table>
		</div>)


	}
}

export default Licences;