import React, { Component } from "react";

class Footer extends Component {
  state = {year:new Date()};
  
  render() {

    return (
    
    <div className="footer-basic">
      <footer>
      </footer>
    </div>

    )
  }


}
  
  export default Footer;
